import styled from "styled-components";
import devices from "../../style/devices";
import { Link } from "react-router-dom";
import { Section } from "../../style/base-components";
import { SectionTitle } from "../../style/text";

export const Container = styled.div`
  min-height: 80vh;
  padding: ${({ theme }) => `${theme.spacing.m3} ${theme.spacing.s3}`};
  margin: 0 auto;
  justify-content: center;

  @media ${devices.tablet} {
    padding: ${({ theme }) => `${theme.spacing.l1} ${theme.spacing.m2}`};
  }
  @media ${devices.desktop} {
    max-width: 1024px;
    padding: ${({ theme }) => theme.spacing.l1};
  }
`;


export const Input = styled.textarea`
  resize: none;
  position: relative;
  width: 50vh;
	background-color: ${({ theme }) => theme.colors.white};
	border-color: ${({ theme }) => theme.colors.grey[300]};
	outline: 0;
  border: 0;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  font-size: 1.5em;
  padding-top: 1em;
  height: 20vh;
  margin-top: 3em;
  font-family: ${({ theme }) => theme.font.family.lato};
  


  @media (min-width: 85em)
  {
      padding-top: 1em;
      padding-left: 10%;
      padding-right: 10%;
  }
  @media (min-width: 70em)
  {
      padding-left: 1em;
      padding-right: 1em;
  }
  @media (min-width: 60em)
  {
      padding-left: 1em;
      padding-right: 1em;
  }
  @media (min-width: 50em)
  {
      padding-left: 1em;
      padding-right: 1em;
  }
`;

export const EmailInput = styled.input`
  font-family: ${({ theme }) => theme.font.family.lato};
  border: 0;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.spacing.xs3};
  margin-bottom: ${({ theme }) => theme.spacing.xs3};
  font-size: ${({ theme }) => theme.font.size[3]};
  background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.grey[300]};
    outline: 0;

`;