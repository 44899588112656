import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import theme from "./theme";

import MarkProBoldTTF from "../../public/fonts/MarkPro-Bold.ttf";
import MarkProBoldOTF from "../../public/fonts/MarkPro-Bold.otf";
import MarkProBoldWOFF from "../../public/fonts/MarkPro-Bold.woff";
import MarkProBoldWOFF2 from "../../public/fonts/MarkPro-Bold.woff2";

import MarkProBookTTF from "../../public/fonts/MarkPro-Book.ttf";
import MarkProBookOTF from "../../public/fonts/MarkPro-Book.otf";
import MarkProBookWOFF from "../../public/fonts/MarkPro-Book.woff";
import MarkProBookWOFF2 from "../../public/fonts/MarkPro-Book.woff2";

import MarkProTTF from "../../public/fonts/MarkPro.ttf";
import MarkProOTF from "../../public/fonts/MarkPro.otf";
import MarkProWOFF from "../../public/fonts/MarkPro.woff";
import MarkProWOFF2 from "../../public/fonts/MarkPro.woff2";

const GlobalStyle = createGlobalStyle`
  ${reset};
  *:focus {
    outline-color: transparent;
  }
  @font-face {
    font-family: MarkPro-Bold ;
    font-display: auto;
    src: 
    url('/fonts/MarkPro-Bold.ttf') format('truetype'),
    url('/fonts/MarkPro-Bold.otf') format('opentype'),
    url('/fonts/MarkPro-Bold.woff') format('woff'),
    url('/fonts/MarkPro-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: MarkPro ;
    font-display: auto;
    src: 
    url('/fonts/MarkPro.ttf') format('truetype'),
    url('/fonts/MarkPro-Bold.otf') format('opentype'),
    url('/fonts/MarkPro.woff') format('woff'),
    url('/fonts/MarkPro.woff2') format('woff2');
  }
  @font-face {
    font-family: MarkPro-Book ;
    font-display: auto;
    src: 
    url('/fonts/MarkPro-Book.ttf') format('truetype'),
    url('/fonts/MarkPro-Bold.otf') format('opentype'),
    url('/fonts/MarkPro-Book.woff') format('woff'),
    url('/fonts/MarkPro-Book.woff2') format('woff2');
  }
  ul {
  list-style-type: circle;
  padding-left: 1em;
}
`;

export default GlobalStyle;
