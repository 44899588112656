import React, { Fragment } from "react";
import Helmet from "react-helmet";
import { StripeProvider, Elements } from "react-stripe-elements";

import {
  Section,
  PageDescription,
  HalfSection
} from "./style";

import {
  SectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  LongSectionTitle
} from "../../style/text";

import PaymentForm from "./Form";

const Billing = props => {
  const { email } = props.match.params;
  return (
    <Fragment>
      <Helmet>
        <title>Spill | Sign Up</title>
        <meta name="description" content="Sign up to Spill." />
        <meta name="keywords" content="Spill,Mental health,Chat,Support" />
      </Helmet>

      <Section>
        <HalfSection>

          <LongSectionTitle>Try Spill free for 7 days,<br/>and just £20 a week therafter.<br/><br/>Recieve two letters a week from your therapist<br/>and write when you want.</LongSectionTitle>
          {/* <SubTitle>Step 2 of 2</SubTitle> */}
          <PageDescription>
            <br/>
            <br/>
            We don't take any money
            until after your trial is up and you can cancel at any time.
            <br/>
            <br/>
            If you'd like to ask questions before signing up, please email <b>hi@spill.chat</b>
          </PageDescription>
        </HalfSection>
        <HalfSection>
          <StripeProvider apiKey={process.env.STRIPE_API_KEY}>
            <Elements>
              <PaymentForm email={email} />
            </Elements>
          </StripeProvider>
        </HalfSection>
      </Section>
    </Fragment>
  );
};
export default Billing;
