import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import {
  TwoColumnSection,
  CopySection,
  Drawing,
  CenteredSection,
  ThinCenteredSection,
  ShadowImg,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  WhiteParagraph,
  LongSectionTitleLetter,
} from "../../style/text";
import {
  TopSection,
  Title,
  BlackTitle,
  Bold,
  ButtonContainer,
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  ListContainer,
  ListGrid,
  ListTitle,
  ListItem,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  CompanyGrid,
  CompanyLogo,
  PressGrid,
  PressLogo,
  Form,
  Input,
  Wrapper,
  CenteredDiv,
  CenteredCTADiv,
  MultiLineInput,
  VideoContainer,
  LetterBox,
} from "./style";

import Onboarding from "../../assets/images/onboarding.png";
import VideoTherapy from "../../assets/images/therapy.png";
import MainMenu from "../../assets/images/main-menu.png";

import Charlie from "../../assets/companyLogos/charlie.png";
import Depop from "../../assets/companyLogos/depop.png";
import Bulb from "../../assets/companyLogos/bulb.png";
import Typeform from "../../assets/companyLogos/typeform.png";
import Bloom from "../../assets/companyLogos/bloom.png";
import Eve from "../../assets/companyLogos/eve.png";
import Whitehat from "../../assets/companyLogos/whitehat.png";
import Technation from "../../assets/companyLogos/technation.png";

import EveningStandardLogo from "../../assets/pressLogos/evening-standard.png";
import GraziaLogo from "../../assets/pressLogos/grazia.png";
import StylistLogo from "../../assets/pressLogos/stylist.png";
import TheIndependentLogo from "../../assets/pressLogos/the-independent.png";
import TheTimesLogo from "../../assets/pressLogos/the-times.png";

import Separator from "../../components/separator/index";
import {
  GetSpill,
  GetUpdates,
  WhiteCTA,
  StayUpToDate,
} from "../../components/callToAction/index";
import {
  TransparentNavigationBar,
  WhiteNavigationBar,
} from "../../components/navigation/index";
import {
  FillButton,
  BorderButton,
  TextButton,
} from "../../components/buttons/index";
import theme from "../../style/theme";

class TeamTherapyHR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      formSuccess: qs.parse(this.props.location.search.jj),
      name: "",
      company: "",
      feedback: "",
    };
    this.sectionRef = React.createRef();
    this.topSectionRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.onScrollDown = this.onScrollDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const values = qs.parse(this.props.location.search);

    this.setState({
      name: values.name,
      company: values.company,
      feedback: values.feedback,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;
    this.setState({
      showNav: scrollY > this.topSectionHeight,
    });
  }

  onScrollDown(event) {
    event.preventDefault();
    console.log(this.sectionRef);
    window.scrollTo({
      left: 0,
      top: this.sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Spill | Therapy for the workplace</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <CenteredSection>
          <Wrapper>
            <LetterBox>
              <LongSectionTitleLetter>Hey 👋</LongSectionTitleLetter>
              <LongSectionTitleLetter>
                We're Spill, a mental health startup (usually) based in Old
                Street.
              </LongSectionTitleLetter>
              <LongSectionTitleLetter>
                Mental health support can be hard to access so we set out to
                make it easier.
              </LongSectionTitleLetter>
              <LongSectionTitleLetter>
                {this.state.name} tried a Group session guided by a Spill
                therapist and thought that Spill could be useful for everyone at{" "}
                {this.state.company}.
              </LongSectionTitleLetter>
              <LongSectionTitleLetter>
                We made this website to make it easier for {this.state.name} to
                send you over more info about Spill.
              </LongSectionTitleLetter>
              <LongSectionTitleLetter>
                If you want to get in touch, you can click below. If not, no
                hard feelings.
              </LongSectionTitleLetter>
              <LongSectionTitleLetter>~ Spill Team</LongSectionTitleLetter>
              <LongSectionTitleLetter>
                P.S Congrats on hiring {this.state.name}. We think they're a
                keeper ;)
              </LongSectionTitleLetter>
            </LetterBox>
          </Wrapper>
        </CenteredSection>
        <Title>👇</Title>
        <BlackTitle>How it works</BlackTitle>
        <TwoColumnSection>
          <CopySection>
            <SectionTitle>All-in-one Tool</SectionTitle>
            <ThinParagraph>
              <a href="https://www.spill.chat/">Spill</a> is there for everyone.
              You can get therapy if you’re not feeling great, ask a therapist a
              question if you need some advice or read our content and tools to
              keep on top of your emotional health.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={MainMenu} alt="MainMenu" />
          </Fade>
        </TwoColumnSection>
        <TwoColumnSection>
          <CopySection>
            <SectionTitle>Simple Setup.</SectionTitle>
            <ThinParagraph>
              Spill integrates with Slack and starts by taking the emotional
              pulse of the company through a quick four-question survey. We then
              offer therapy sessions to those who are struggling.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={Onboarding} alt="Onboarding" />
          </Fade>
        </TwoColumnSection>
        <TwoColumnSection>
          <CopySection>
            <SectionTitle>Active Support</SectionTitle>
            <ThinParagraph>
              Our therapists are fully trained professionals who are there to
              actually support your team. And we only hire the best out there,
              so you’re in safe hands.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={VideoTherapy} alt="Video Therapy" />
          </Fade>
        </TwoColumnSection>
        <CenteredSection>
          <LongSectionTitle centered>
            Feedback from {this.state.name}
          </LongSectionTitle>
          <LetterBox>
            <LongSectionTitleLetter>
              {this.state.feedback}
            </LongSectionTitleLetter>
          </LetterBox>
        </CenteredSection>

        <CenteredSection>
          <SectionTitle centered>Interested in Spill?</SectionTitle>

          <CenteredCTADiv>
            <a href="https://meetings.hubspot.com/ally48">
              <FillButton
                text="Get in touch"
                h
                borderColor={theme.colors.blue.normal}
                fontColor={theme.colors.blue.normal}
              />
            </a>
          </CenteredCTADiv>
        </CenteredSection>
        <Separator />

        <CenteredSection>
          <LongSectionTitle centered>Trusted by</LongSectionTitle>
          <PressGrid>
            <PressLogo src={Depop} alt="charliehr" />
            <PressLogo src={Bulb} alt="seedcamp" />
            <PressLogo src={Typeform} alt="google for startups" />
            <PressLogo src={Bloom} alt="charliehr" />
          </PressGrid>
          <PressGrid>
            <PressLogo src={Charlie} alt="kindred capital" />

            <PressLogo src={Eve} alt="seedcamp" />
            <PressLogo src={Whitehat} alt="google for startups" />
            <PressLogo src={Technation} alt="kindred capital" />
          </PressGrid>
        </CenteredSection>
        <Separator />
        <CenteredSection>
          <Fragment>
            <SectionTitle>FAQs</SectionTitle>

            <Wrapper>
              <LongSectionTitle>How much does Spill cost? </LongSectionTitle>
              <Paragraph>
                You can take a look at our pricing page{" "}
                <a href="https://www.spill.chat/pricing">here</a>.
              </Paragraph>
              <LongSectionTitle>
                Is Spill confidential for people to use?
              </LongSectionTitle>
              <Paragraph>
                Absolutely. Spill is completely confidential for employees. We
                will never tell you who has accessed therapy on Spill.
              </Paragraph>
              <LongSectionTitle>
                Who are your therapists? What are their qualifications and how
                do you select them?
              </LongSectionTitle>
              <Paragraph>
                All our counsellors are BACP-registered or equivalent, have
                completed a minimum three-year degree in the field and have a
                minimum of 200 hours’ client experience, but usually
                significantly more. We have a stringent five-stage hiring
                process for counsellors, and to date have only accepted 5% of
                all qualified applicants. For comparison, Oxford University’s
                acceptance rate in 2015 was 17.5%.
              </Paragraph>
            </Wrapper>
          </Fragment>
        </CenteredSection>
        <CenteredSection>
          <SectionTitle centered>Interested in Spill?</SectionTitle>

          <CenteredCTADiv>
            <a href="https://meetings.hubspot.com/ally48">
              <FillButton
                text="Get in touch"
                h
                borderColor={theme.colors.blue.normal}
                fontColor={theme.colors.blue.normal}
              />
            </a>
          </CenteredCTADiv>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default withRouter(TeamTherapyHR);
