import styled from "styled-components";
import devices from "../../style/devices";

export const Section = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background-color: ${(props) => props.backgroundColor};
  padding: 2rem 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 10rem 5rem;
    overflow: hidden;
  }
`;

export const TitleSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing.m2} ${theme.spacing.s3} `};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.tablet} {
    padding: ${({ theme }) =>
      `${theme.spacing.m1} ${theme.spacing.m2} ${theme.spacing.m3}`};
  }
  @media ${devices.desktop} {
    padding: ${({ theme }) =>
      `${theme.spacing.m3} ${theme.spacing.m3} ${theme.spacing.m3} ${
        theme.spacing.m2
      }`};
    margin: 0 auto;
  }
`;

export const HalfSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 90%;
  height: 100%;
  align-items: start;
  justify-content: start;
  @media (min-width: 768px) {
    grid-row: 1;
    width: ${(props) => props.width};
  }
`;

export const PageTitle = styled.h1`
  font-family: MarkPro-Bold;
  color: black;
  font-size: 2.5rem;
  padding-bottom: 1rem;
`;

export const PageDescription = styled.p`
  font-family: Lato;
  color: grey;
  padding-bottom: 3rem;
  line-height: 1.5em;
`;

export const SubTitle = styled.h3`
  font-family: Lato;
  font-weight: 800;
  color: grey;
  text-transform: uppercase;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  letter-spacing: 0.05rem;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 3rem 0.5rem;
  height: 100%;
`;

export const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => props.gridColumn};
  /* justify-self: start; */
  icon-color: #c4f0ff;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-size: 15px;
  font-smoothing: antialiased;
  ":-webkit-autofill": {
    color: #fce883;
  }
  "::placeholder": {
    color: #87bbfd;
  }
  border-bottom: 2px solid #001e49;
`;

export const ElementTitle = styled.h4`
  font-family: Lato;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  color: #333333;
`;

export const PayNowButton = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.8rem;
  border-radius: 0.5rem;
  border: 0.08rem solid grey;
  cursor: pointer;
  color: grey;
  background-color: white;
  &:hover {
    color: black;
    border-color: black;
  }
`;

export const EmailInput = styled.input`
  icon-color: #c4f0ff;
  color: black;
  font-weight: 500;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-size: 15px;
  font-smoothing: antialiased;
  ":-webkit-autofill": {
    color: #fce883;
  }
  "::placeholder": {
    color: #87bbfd;
  }
  border-style: none;
`;
