import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import {
  TwoColumnSection,
  CopySection,
  Drawing,
  CenteredSection,
  ShadowImg,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  WhiteParagraph,
} from "../../style/text";
import {
  TopSection,
  Title,
  Bold,
  ButtonContainer,
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  ListContainer,
  ListGrid,
  ListTitle,
  ListItem,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  CompanyGrid,
  CompanyLogo,
  PressGrid,
  PressLogo,
  Form,
  Input,
  Wrapper,
  CenteredDiv,
  CenteredCTADiv,
  MultiLineInput,
  VideoContainer,
} from "./style";

import Onboarding from "../../assets/images/onboarding.png";
import VideoTherapy from "../../assets/images/therapy.png";
import MainMenu from "../../assets/images/main-menu.png";

import Charlie from "../../assets/companyLogos/charlie.png";
import Depop from "../../assets/companyLogos/depop.png";
import Bulb from "../../assets/companyLogos/bulb.png";
import Typeform from "../../assets/companyLogos/typeform.png";
import Bloom from "../../assets/companyLogos/bloom.png";
import Eve from "../../assets/companyLogos/eve.png";
import Whitehat from "../../assets/companyLogos/whitehat.png";
import Technation from "../../assets/companyLogos/technation.png";

import EveningStandardLogo from "../../assets/pressLogos/evening-standard.png";
import GraziaLogo from "../../assets/pressLogos/grazia.png";
import StylistLogo from "../../assets/pressLogos/stylist.png";
import TheIndependentLogo from "../../assets/pressLogos/the-independent.png";
import TheTimesLogo from "../../assets/pressLogos/the-times.png";

import Separator from "../../components/separator/index";
import {
  GetSpill,
  GetUpdates,
  WhiteCTA,
  StayUpToDate,
} from "../../components/callToAction/index";
import {
  TransparentNavigationBar,
  WhiteNavigationBar,
} from "../../components/navigation/index";
import {
  FillButton,
  BorderButton,
  TextButton,
} from "../../components/buttons/index";
import theme from "../../style/theme";

class Startups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      formSuccess: false,
    };
    this.sectionRef = React.createRef();
    this.topSectionRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeInvestor = this.onChangeInvestor.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onScrollDown = this.onScrollDown.bind(this);
  }

  componentDidMount() {
    this.topSectionHeight = this.topSectionRef.current.clientHeight;
    window.addEventListener("scroll", this.handleScroll);
    const hash = qs.parse(this.props.location.hash);
    console.log(this.props.location.pathname);
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeCompanyName(event) {
    this.setState({ companyName: event.target.value });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeInvestor(event) {
    this.setState({ investor: event.target.value });
  }

  submitForm(event) {
    const formData = {
      name: this.state.name,
      companyName: this.state.companyName,
      email: this.state.email,
      investor: this.state.investor,
      subject: "New Credit Claim",
    };

    fetch("/api/new-gift", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("cool");
        this.setState({ formSuccess: true });
      })
      .catch(console.log);

    console.log(formData);
    /*this.setState({ error: false, formError: "" });
    event.preventDefault();
    if (!this.formValidation()) return;
    const formData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      firstName: this.state.firstName
    };
    fetch("/api/create-user", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          if (this.state.email.includes("northumbria.ac.uk"))
            this.setState({ stage: 3 });
          else this.setState({ stage: 2 });
        } else {
          res.json().then(body => {
            this.setState({ formError: body.message, error: true });
          });
        }
      })
      .catch(console.log);*/
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;
    this.setState({
      showNav: scrollY > this.topSectionHeight,
    });
  }

  onScrollDown(event) {
    event.preventDefault();
    console.log(this.sectionRef);
    window.scrollTo({
      left: 0,
      top: this.sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Spill | Message-based therapy for the workplace</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <TransparentNavigationBar />
        <TopSection ref={this.topSectionRef}>
          <React.Fragment>
            <Title>
              <Bold>Spill for Christmas.</Bold>
            </Title>
            <CenteredDiv>
              <a href="https://www.spill.chat/install/">
                <FillButton
                  text="Download Spill"
                  h
                  borderColor={theme.colors.blue.normal}
                  fontColor={theme.colors.blue.normal}
                />
              </a>
              <WhiteParagraph>
                £900 worth of free therapy for teams until 5th January.
              </WhiteParagraph>
            </CenteredDiv>
          </React.Fragment>
        </TopSection>

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>Simple Setup.</SectionTitle>
            <ThinParagraph>
              Spill integrates with Slack and starts by taking the emotional
              pulse of the company through a quick four-question survey. We then
              offer therapy sessions to those who are struggling.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={Onboarding} alt="Onboarding" />
          </Fade>
        </TwoColumnSection>

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>Active Support</SectionTitle>
            <ThinParagraph>
              Our therapists are fully trained professionals who are there to
              actually support your team. And we only hire the best out there,
              so you’re in safe hands.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={VideoTherapy} alt="Video Therapy" />
          </Fade>
        </TwoColumnSection>

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>All-in-one Tool</SectionTitle>
            <ThinParagraph>
              Spill is there for everyone. You can get therapy if you’re not
              feeling great, ask a therapist a question if you need some advice
              or read our content and tools to keep on top of your emotional
              health.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={MainMenu} alt="MainMenu" />
          </Fade>
        </TwoColumnSection>

        <CenteredSection>
          <SectionTitle centered>
            Get Spill for your team over the Holidays.
          </SectionTitle>

          <ItemDescription>
            10 therapy sessions and 10 questions to a therapist.
          </ItemDescription>

          <CenteredCTADiv>
            <a href="https://www.spill.chat/install/">
              <FillButton
                text="Download Spill"
                h
                borderColor={theme.colors.blue.normal}
                fontColor={theme.colors.blue.normal}
              />
            </a>
          </CenteredCTADiv>
        </CenteredSection>
        <Separator />

        <CenteredSection ref={this.sectionRef}>
          <Fragment>
            <SectionTitle centered>
              Install in 3 clicks. No commitment. No catch. Delete when you
              want.
            </SectionTitle>

            <VideoContainer>
              <iframe
                src="https://drive.google.com/file/d/1mLgzNhSCBPuIe8OriLkPBf9QXPbg86be/preview"
                width="730"
                height="500"
              />
            </VideoContainer>

            <CenteredCTADiv>
              <a href="https://www.spill.chat/install/">
                <FillButton
                  text="Download Spill"
                  h
                  borderColor={theme.colors.blue.normal}
                  fontColor={theme.colors.blue.normal}
                />
              </a>
              <ItemDescription>
                Read more about{" "}
                <a href="https://www.notion.so/spill/Spill-for-Christmas-2430f8d75a6a4747aa9fe8d57a243778">
                  installing Spill
                </a>
                .
              </ItemDescription>
            </CenteredCTADiv>
          </Fragment>
        </CenteredSection>

        <CenteredSection>
          <SectionTitle centered>Trusted by...</SectionTitle>

          <PressGrid>
            <PressLogo src={Depop} alt="charliehr" />
            <PressLogo src={Bulb} alt="seedcamp" />
            <PressLogo src={Typeform} alt="google for startups" />
            <PressLogo src={Bloom} alt="charliehr" />
          </PressGrid>
          <PressGrid>
            <PressLogo src={Charlie} alt="kindred capital" />

            <PressLogo src={Eve} alt="seedcamp" />
            <PressLogo src={Whitehat} alt="google for startups" />
            <PressLogo src={Technation} alt="kindred capital" />
          </PressGrid>
        </CenteredSection>

        <Separator />
        <CenteredSection>
          <Fragment>
            <SectionTitle>FAQs</SectionTitle>

            <Wrapper>
              <LongSectionTitle>Why is it free? </LongSectionTitle>
              <Paragraph>
                This is a way for us to spread the word about Spill while doing
                some good this Christmas.
              </Paragraph>
              <LongSectionTitle>
                What happens after 5th January?
              </LongSectionTitle>
              <Paragraph>
                On 6th January you will automatically transition to the basic
                free plan, which includes access to content and tools (but not
                therapy sessions).
              </Paragraph>
              <LongSectionTitle>
                What happens if I use the 10 sessions and 10 questions to a
                therapist before 5th January?
              </LongSectionTitle>
              <Paragraph>
                We'll turn off those features as and when you use them, people
                will be redirected to the content and tools.
              </Paragraph>
              <LongSectionTitle>How do I delete the App?</LongSectionTitle>
              <Paragraph>
                If at the end of the trial you decide that Spill isn't for you,
                all you have to do is go to the app, click on the 'About' page,
                click on 'Settings', and then scroll to the bottom and click
                'Remove app'. Or get the Slack admin to do this. You won’t hear
                from us after that.
              </Paragraph>
              <LongSectionTitle>
                Are you going to spam my team?
              </LongSectionTitle>
              <Paragraph>
                No. After the initial onboarding message your team will not
                receive anything else from Spill.
              </Paragraph>
              <LongSectionTitle>What about data?</LongSectionTitle>
              <Paragraph>
                We do not keep any data. We are fully GDPR and HIPAA-compliant.
                You can learn more about data security{" "}
                <a href="https://www.notion.so/spill/Spill-for-Christmas-2430f8d75a6a4747aa9fe8d57a243778">
                  here
                </a>
                .
              </Paragraph>
            </Wrapper>
          </Fragment>
        </CenteredSection>
        <CenteredSection>
          <SectionTitle centered>
            Get £900 worth of therapy credit for your team over the holidays
          </SectionTitle>

          <CenteredCTADiv>
            <a href="https://www.spill.chat/install/">
              <FillButton
                text="Download Spill"
                h
                borderColor={theme.colors.blue.normal}
                fontColor={theme.colors.blue.normal}
              />
            </a>
          </CenteredCTADiv>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default withRouter(Startups);
