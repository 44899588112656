import styled from "styled-components";
import devices from "../../style/devices";
import { Paragraph, SmallHeading } from "../../style/text";

export const TopSection = styled.section`
  background-color: ${({ theme }) => theme.colors.blue.normal};
  padding: ${({ theme }) => `${theme.spacing.m2} ${theme.spacing.s3} `};
  min-height: 60vh;

  @media ${devices.tablet} {
    min-height: 50vh;
    padding: ${({ theme }) =>
      `${theme.spacing.m1} ${theme.spacing.m2} ${theme.spacing.m3}`};
  }
  @media ${devices.desktop} {
    min-height: 25rem;
    padding: ${({ theme }) =>
      `${theme.spacing.s3} ${theme.spacing.l1} ${theme.spacing.m3}`};
    margin: 0 auto;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: MarkPro-Book;
  font-size: ${({ theme }) => theme.font.size[8]};
  padding-bottom: 4rem;
  letter-spacing: ${({ theme }) => theme.letterSpacing.small};
  text-align: center;
  padding-top: 5rem;

  @media ${devices.tablet} {
    line-height: ${({ theme }) => theme.lineHeight.small};
    font-size: ${({ theme }) => theme.font.size[10]};
    padding-bottom: ${({ theme }) => theme.spacing.m1};
  }
  @media ${devices.desktop} {
    white-space: pre-wrap;
    line-height: ${({ theme }) => theme.lineHeight.small};
    font-size: ${({ theme }) => theme.font.size[10]};
    padding-top: 8rem;
  }
  @media ${devices.monitor} {
    margin: 0 auto;
    width: 50%;
  }
`;

export const BlackTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-family: MarkPro-Book;
  font-size: ${({ theme }) => theme.font.size[8]};
  padding-bottom: 4rem;
  letter-spacing: ${({ theme }) => theme.letterSpacing.small};
  text-align: center;
  padding-top: 5rem;

  @media ${devices.tablet} {
    line-height: ${({ theme }) => theme.lineHeight.small};
    font-size: ${({ theme }) => theme.font.size[10]};
    padding-bottom: ${({ theme }) => theme.spacing.m1};
  }
  @media ${devices.desktop} {
    white-space: pre-wrap;
    line-height: ${({ theme }) => theme.lineHeight.small};
    font-size: ${({ theme }) => theme.font.size[10]};
    padding-top: 8rem;
  }
  @media ${devices.monitor} {
    margin: 0 auto;
    width: 50%;
  }
`;

export const Bold = styled.span`
  font-family: MarkPro-Bold;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;

  @media ${devices.tablet} {
    min-height: auto;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  @media ${devices.desktop} {
    flex-direction: row;
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.s2};
  grid-auto-rows: 1fr;

  @media ${devices.tablet} {
    grid-gap: ${({ theme }) => theme.spacing.s3};
  }
  @media ${devices.desktop} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spacing.s3};
    padding: ${({ theme }) => theme.spacing.s3};
    max-width: 65rem;
    width: 100%;
  }
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 5px 15px hsl(0, 0%, 0%, 0.2);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing.m1} ${theme.spacing.s3}`};
  align-items: center;
  border-top-style: solid;
  border-top-color: ${({ color }) => color};
  border-top-width: ${({ theme }) => theme.spacing.xs3};
  max-width: 20rem;
`;

export const CardImage = styled.img`
  height: ${({ theme }) => theme.spacing.l1};
  display: block;
  padding-bottom: ${({ theme }) => theme.spacing.m1};
`;

export const CardTitle = styled(SmallHeading)`
  padding-bottom: ${({ theme }) => theme.spacing.s2};

  @media ${devices.tablet} {
  }

  @media ${devices.desktop} {
  }
`;

export const ListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: ${({ theme }) => theme.spacing.m1};
  justify-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.m1};
  grid-auto-rows: 1fr;

  @media ${devices.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing.m1};
  }

  @media ${devices.desktop} {
    grid-column-gap: ${({ theme }) => theme.spacing.s1};
    padding-bottom: ${({ theme }) => theme.spacing.m3};
    &:nth-of-type(2) {
      padding-bottom: 0;
    }
  }
`;

export const ListItem = styled.div`
  align-content: left;
  justify-items: center;
  justify-self: center;
  margin: 0 auto;
`;

export const ItemIcon = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.s1};
`;

export const ItemTitle = styled(SmallHeading)``;

export const ItemDescription = styled(Paragraph)`
  width: 90%;
  text-align: center;
  max-width: 40rem;
  margin: 0 auto;
`;

export const CompanyGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.xs3};
  padding-top: ${({ theme }) => theme.spacing.s3};
  grid-auto-rows: 1fr;
  position: relative;

  @media ${devices.tablet} {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing.s3};
  }
`;

export const CompanyLogo = styled.img`
  width: auto;
  height: 2rem;
  justify-self: center;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) opacity(0.6);

  @media ${devices.tablet} {
    height: 2.5rem;
  }

  @media ${devices.desktop} {
    height: 3rem;
  }
`;

export const PressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.s1};
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.m1};
  grid-auto-rows: 1fr;

  @media ${devices.tablet} {
    grid-column-gap: ${({ theme }) => theme.spacing.s3};
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const PressLogo = styled.img`
  height: auto;
  width: 5rem;
  justify-self: center;

  @media ${devices.tablet} {
    height: auto;
    width: 7rem;
    &:nth-of-type(2) {
      display: inline;
    }
  }
`;

export const Form = styled.form`
  padding: ${({ theme }) => `${theme.spacing.m1} 0`};
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${devices.tablet} {
    width: 80%;
    margin: 0 auto;
  }
`;

export const Input = styled.input`
  font-family: ${({ theme }) => theme.font.family.lato};
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border: 0;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  border-color: transparent;
  padding: ${({ theme }) => theme.spacing.xs3};
  margin-bottom: ${({ theme }) => theme.spacing.xs3};
  font-size: ${({ theme }) => theme.font.size[3]};

  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.grey[300]};
    outline: 0;
  }
`;

export const MultiLineInput = styled.textarea`
  height: 5rem;
  font-family: ${({ theme }) => theme.font.family.lato};
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border: 0;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  border-color: transparent;
  border-color: ${({ theme }) => theme.colors.grey[300]};
  padding: ${({ theme }) => theme.spacing.xs3};
  margin-bottom: ${({ theme }) => theme.spacing.xs3};
  font-size: ${({ theme }) => theme.font.size[3]};

  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.grey[300]};
    outline: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    max-width: 55rem;
  }
`;

export const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const CenteredCTADiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.s1};
`;

export const VideoContainer = styled.div`
  display: none;

  @media ${devices.tablet} {
    position: relative;
    display: block;
    paddingbottom: 100px;
    height: 500px;
  }
`;

export const LetterBox = styled.div`
  width: 100%;
  align-self: center;
  box-shadow: rgba(84, 70, 35, 0.15) 0px 6px 20px,
    rgba(84, 70, 35, 0.15) 0px 1px 3px;
  height: auto;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.m1};
  @media ${devices.tablet} {
    width: 70%;
    justify-self: center;
    height: auto;
  }
`;
