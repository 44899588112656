import styled, { keyframes } from "styled-components";
import devices from "../../style/devices";

const moveRightAndBack = keyframes`
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0px);
  }
`;

export const Button = styled.button`
  display: inline-block;
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-size: ${({ theme }) => theme.font.size[1]};
  font-weight: bold;
  letter-spacing: ${({ theme }) => theme.letterSpacing.large};
  text-transform: uppercase;
  border-radius: 1rem;
  padding: ${({ theme }) => `${theme.spacing.xs2} ${theme.spacing.s1}`};
  cursor: pointer;
  border-width: 0;
  overflow: hidden;
  margin: 0;
  text-align: center;
  width: fit-content;

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[2]};
    letter-spacing: ${({ theme }) => theme.letterSpacing.large};
    margin: 0;
  }

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[4]};
    letter-spacing: ${({ theme }) => theme.letterSpacing.large};
  }
`;

export const ButtonSolid = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.m1}`};
  background-color: ${({ fillColor, theme }) =>
    fillColor ? fillColor : theme.colors.primary.normal};
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.colors.blue.normal};
  border-style: solid;
  border-width: ${({ theme }) => theme.borderWidth.m};
  border-color: ${({ borderColor, theme }) =>
    borderColor || theme.colors.primary.normal};
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  &:hover {
    opacity: 0.7;
  }
`;

export const ButtonBorder = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};
  border-color: ${({ borderColor, theme }) =>
    borderColor || theme.colors.primary.normal};
  color: ${({ fontColor, theme }) => fontColor || theme.colors.primary.normal};
  border-style: solid;
  border-width: ${({ theme }) => theme.borderWidth.m};
  background-color: transparent;
  &:hover {
    opacity: 0.8;
  }
`;

export const ClearButton = styled.button`
  color: ${({ theme }) => theme.colors.blue.main};
  text-transform: uppercase;
  background-color: transparent;
  display: inline-block;
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size[1]};
  letter-spacing: ${({ theme }) => theme.letterSpacing.large};
  text-transform: uppercase;
  border: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: ${({ theme }) => theme.spacing.s3};
  cursor: pointer;

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[2]};
    letter-spacing: ${({ theme }) => theme.letterSpacing.large};
  }

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[3]};
    letter-spacing: ${({ theme }) => theme.letterSpacing.large};
  }
`;

export const ArrowWrapper = styled.div`
  display: inline-block;

  ${ClearButton}:hover & {
    animation: ${moveRightAndBack} 1s ease-in-out;
  }
`;
