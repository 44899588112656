import React, { Component, useState } from "react";
import Fade from "react-reveal/Fade";
import { withTheme } from "styled-components";

import { FillButton, BorderButton } from "../../components/buttons/index";
import {
  Section,
  Title,
  ButtonContainer,
  Form,
  EmailInput,
  BorderEmailInput,
  Button,
  WhiteSection,
  WhiteSectionCentered,
  BlackTitle,
  GreyBackgroundEmailInput,
  CenteredDiv
} from "./style";
import theme from "../../style/theme";

export class GetSpill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailInput(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // TODO: add checks to form input
    this.props.handleCTA(this.state.email)
    
    
  }

  render() {
    return (
  <Section>
    <Title>Get Spill for only £20 a week</Title>
    <Fade top>
        <Form>
            <BorderEmailInput
              type="email"
              value={this.state.email}
              placeholder="Your email address"
              onChange={this.handleEmailInput}
              title="email"
              aria-label="email"
            />
            <BorderButton text="sign up" action={this.handleSubmit} />
          </Form>
    </Fade>
  </Section>
  )}
};

export class GetUpdates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailInput(event) {
    this.setState({ email: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    // TODO: add checks to form input
    const formData = {
      email: this.state.email
    };
    fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => this.setState({ email: "" }));
  }

  render() {
    return (
      <Section>
        <Title>Get monthly updates on the Spill story.</Title>
        <Fade top>
          <Form>
            <EmailInput
              type="email"
              value={this.state.email}
              placeholder="Your email"
              onChange={this.handleEmailInput}
              title="email"
              aria-label="email"
            />
            <FillButton text="sign up" action={this.handleSubmit} />
          </Form>
        </Fade>
      </Section>
    );
  }
}

export const StayUpToDate = withTheme(({ theme }) => {
  const [email, setEmail] = useState("");
  const handleEmailInput = event => setEmail(event.target.value);
  const handleSubmit = event => {
    event.preventDefault();
    // TODO: add checks to form input
    const formData = {
      email
    };
    fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => setEmail(""));
  };
  return (
    <WhiteSection>
      <Fade top>
        <BlackTitle>Stay up to date with the Spill story.</BlackTitle>
        <Form>
          <GreyBackgroundEmailInput
            type="email"
            value={email}
            placeholder="Your email "
            onChange={handleEmailInput}
            title="email"
            aria-label="email"
          />
          <BorderButton
            borderColor={theme.colors.blue.normal}
            fontColor={theme.colors.blue.normal}
            text="get monthly updates"
            action={handleSubmit}
          />
        </Form>
      </Fade>
    </WhiteSection>
  );
});

export class BlogCTA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailInput(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // TODO: add checks to form input
    this.props.handleCTA(this.state.email)
    
    
  }

  render() {
    return (
      
          <Form>
            <BorderEmailInput
              type="email"
              value={this.state.email}
              placeholder="Your email address"
              onChange={this.handleEmailInput}
              title="email"
              aria-label="email"
            />
            <BorderButton text="sign up" action={this.handleSubmit} />
          </Form>
    );
  }
}

export const OneButtonCTA = ({ title, buttonText, action, id }) => (
  <Section id={id}>
    <Title>{title}</Title>
    <Fade top>
      <CenteredDiv>
        <FillButton action={action} text={buttonText} />
      </CenteredDiv>
    </Fade>
  </Section>
);

export class WhiteCTA extends Component {
    constructor(props) {
    super(props);

    this.state = {
      email: ""
    };

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailInput(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // TODO: add checks to form input
    this.props.handleCTA(this.state.email)
    
    
  }

  render() {
    return (


  <WhiteSection>
  <CenteredDiv>
    <BlackTitle>Get Spill for only £20 a week</BlackTitle>
    <Fade top>
       <Form>
            <BorderEmailInput
              type="email"
              value={this.state.email}
              placeholder="Your email address"
              onChange={this.handleEmailInput}
              title="email"
              aria-label="email"
            />
            <BorderButton text="sign up" action={this.handleSubmit} />
          </Form>
    </Fade>
    </CenteredDiv>
  </WhiteSection>
  )}
};
