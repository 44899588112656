import React, { Component, Fragment } from "react";
import ReactSVG from "react-svg";
import { Helmet } from "react-helmet";

import { ExclamationMarkIcon } from "../../assets/icons";
import { FillButton } from "../../components/buttons/index";
import { CenteredSection, DrawingQuote } from "../../style/base-components";
import { SectionTitle, Paragraph, GreenParagraph } from "../../style/text";
import theme from "../../style/theme";
import {
  ThreeColumns,
  Underline,
  Form,
  BigForm,
  ExtraBigForm,
  Input,
  Wrapper,
  LinkText,
  LinkTextTertiary,
  Stage,
  Reason,
  Description,
  IconWrapper,
  ErrorMessage,
  StoreContainer,
  StoreImg,
  ButtonContainer,
  CenteredDiv,
  FullSizeSection,
  ButtonWrapper,
  BigBorderButton,
  SmallBorderButton,
  ProgressBarOuter,
  ProgressBarInner,
} from "./style";
import IOSStore from "../../assets/IOSAPPSTORE.svg";

import Waiting from "../../assets/images/waiting.png";

import {
  TransparentNavigationBar,
  QuoteNavigationBar,
} from "../../components/navigation/index";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      firstName: "",
      company: "",
      email: "",
      phone: "",
      employees: null,
      mentalHealthDay: null,
      transparentScore: null,
      holidayPledge: null,
      letThemGoSurfing: null,
      basedUK: false,
      basedEurope: false,
      basedUS: false,
      basedRoW: false,
      holidayPledge: null,
      letThemGoSurfing: null,
      chatSystem: "",
      yesToggle: false,
      noToggle: false,
      slackToggle: false,
      teamsToggle: false,
      otherToggle: false,
      currentDiscount: 0,
      disabledProperty: true,
    };

    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeEmployees = this.onChangeEmployees.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
  }
  componentDidMount() {}

  onChangeFirstName(event) {
    this.setState({ firstName: event.target.value });
    if (this.state.company != "") {
      this.setState({ disabledProperty: false });
    }
  }

  onChangeCompany(event) {
    this.setState({ company: event.target.value });
    if (this.state.firstName != "") {
      this.setState({ disabledProperty: false });
    }
    console.log(this.state.disabledProperty);
  }

  onChangeEmployees(event) {
    this.setState({ employees: event.target.value });
    this.setState({ disabledProperty: false });
  }
  onChangeEmail(event) {
    this.setState({ email: event.target.value });
    this.setState({ disabledProperty: false });
  }

  formValidation() {
    if (this.state.firstName.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter your name",
      });
      return false;
    }
    if (this.state.company.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter your company name",
      });
      return false;
    }
    return true;
  }

  submitForm(stage) {
    if (stage == 0) {
      this.setState({ stage: 1, disabledProperty: true });
    } else if (stage == 1) {
      if (this.state.employees >= 200) {
        window.open("https://www.spill.chat/pricing-page-large-businesses");
      } else {
        this.setState({ stage: 2, disabledProperty: true });
      }
    } else if (stage == 2) {
      this.setState({ stage: 3, disabledProperty: true });
    } else if (stage == 3) {
      this.setState({ stage: 4, disabledProperty: false });
    } else if (stage == 4) {
      this.setState({ stage: 5, disabledProperty: true });
    } else if (stage == 5) {
      this.setState({
        stage: 6,
        yesToggle: false,
        noToggle: false,
        disabledProperty: true,
      });
      if (this.state.mentalHealthDay == true) {
        this.setState({ currentDiscount: this.state.currentDiscount + 8 });
      }
    } else if (stage == 6) {
      this.setState({
        stage: 7,
        yesToggle: false,
        noToggle: false,
        disabledProperty: true,
      });
      if (this.state.transparentScore == true) {
        this.setState({ currentDiscount: this.state.currentDiscount + 5 });
      }
    } else if (stage == 7) {
      if (this.state.holidayPledge == true) {
        console.log("in threee");
        this.setState({ currentDiscount: this.state.currentDiscount + 7 });
        this.setState({
          stage: "final",
          yesToggle: false,
          noToggle: false,
          disabledProperty: true,
        }, () =>
        this.pricingFormula());
        console.log("stage", this.state.currentDiscount);
      }
    }
     else {
      this.pricingFormula();
    }
    console.log(event);
    //this.props.history.push("/sign-up/" + this.state.email);
  }

  toggleValue(event, button) {
    this.setState({ disabledProperty: false });
    const {
      target: { name, value },
    } = event;
    if (button == "yesToggle") {
      this.setState({
        yesToggle: true,
        noToggle: false,
        [name]: true,
      });
    } else if (button == "noToggle") {
      this.setState({
        yesToggle: false,
        noToggle: true,
        [name]: false,
      });
    } else if (button == "1Toggle") {
      this.setState({
        slackToggle: true,
        teamsToggle: false,
        otherToggle: false,
        chatSystem: "Slack",
      });
    } else if (button == "2Toggle") {
      this.setState({
        slackToggle: false,
        teamsToggle: true,
        otherToggle: false,
        chatSystem: "Teams",
      });
    } else if (button == "3Toggle") {
      this.setState({
        slackToggle: false,
        teamsToggle: false,
        otherToggle: true,
        chatSystem: "Other",
      });
    } else if (button == "UKToggle") {
      if (this.state.basedUK == false) {
        this.setState({
          basedUK: true,
        });
      } else {
        this.setState({
          basedUK: false,
        });
      }
    } else if (button == "EuropeToggle") {
      if (this.state.basedEurope == false) {
        this.setState({
          basedEurope: true,
        });
      } else {
        this.setState({
          basedEurope: false,
        });
      }
    } else if (button == "USToggle") {
      if (this.state.basedUS == false) {
        this.setState({
          basedUS: true,
        });
      } else {
        this.setState({
          basedUS: false,
        });
      }
    } else if (button == "RoWToggle") {
      if (this.state.basedRoW == false) {
        this.setState({
          basedRoW: true,
        });
      } else {
        this.setState({
          basedRoW: false,
        });
      }
    }
    console.log(
      this.state.yesToggle,
      this.state.noToggle,
      this.state.mentalHealthDay,
      this.state.transparentScore,
      this.state.holidayPledge,
      this.state.chatSystem
    );
    //this.props.history.push("/sign-up/" + this.state.email);
  }

  pricingFormula() {
    console.log("pricing", this.state.currentDiscount);
    const softwarePrice = 4;
    const sessionPrice = 45;
    const employees = parseInt(this.state.employees);
    const discountAsPercentage = this.state.currentDiscount / 100;
    const discountMultiplyer = 1.2 - discountAsPercentage;

    const softwareCostPerMonth = softwarePrice * employees;

    const properOneOffPerYear = Math.ceil(employees * 0.8 + 10);
    const properCoursePerYear = Math.ceil(employees * 0.2 + 4);
    const properTherapyCostPerYear =
      properOneOffPerYear * sessionPrice +
      properCoursePerYear * 6.5 * sessionPrice +
      560;

    const properPricePerMonthPreDiscountMultiplyer =
      properTherapyCostPerYear / 12 + softwareCostPerMonth;
    const properPricePerMonth =
      properPricePerMonthPreDiscountMultiplyer * discountMultiplyer;
    const properPricePerEmployee = properPricePerMonth / employees;

    const properPercentCourses = Math.floor(
      (properCoursePerYear / employees) * 100
    );
    const properDiscountPerMonth = properPricePerMonth * discountAsPercentage;

    console.log(
      "costyear",
      properTherapyCostPerYear,
      "predis",
      properPricePerMonthPreDiscountMultiplyer,
      "dis",
      discountMultiplyer,
      "per mo",
      properPricePerMonth,
      "emp",
      employees,
      "ppemp",
      properPricePerEmployee,
      "discount percent",
      discountAsPercentage
    );

    const liteOneOffPerYear = Math.ceil(employees * 1.2 + 10);
    const liteCoursePerYear = 0;
    const liteTherapyCostPerYear =
      liteOneOffPerYear * sessionPrice +
      liteCoursePerYear * 6.5 * sessionPrice +
      280;

    const litePricePerMonthPreDiscountMultiplyer =
      liteTherapyCostPerYear / 12 + softwareCostPerMonth;
    const litePricePerMonth =
      litePricePerMonthPreDiscountMultiplyer * discountMultiplyer;
    const litePricePerEmployee = litePricePerMonth / employees;

    const liteDiscountPerMonth = litePricePerMonth * discountAsPercentage;

    const URL =
      "https://www.spill.chat/pricing-page?pr_p_m=" +
      properPricePerMonth.toFixed(2) +
      "&pr_l_m=" +
      litePricePerMonth.toFixed(2) +
      "&d_p_m=" +
      properDiscountPerMonth.toFixed(2) +
      "&d_l_m=" +
      liteDiscountPerMonth.toFixed(2) +
      "&pr_p_e=" +
      properPricePerEmployee.toFixed(2) +
      "&pr_l_e=" +
      litePricePerEmployee.toFixed(2) +
      "&p_c=" +
      properCoursePerYear +
      "&p_o=" +
      properOneOffPerYear +
      "&l_o=" +
      liteOneOffPerYear +
      "&p_c_p=" +
      properPercentCourses.toFixed();

    const URLSMB =
      "https://www.spill.chat/pricing-page-5-15?pr_p_m=" +
      properPricePerMonth.toFixed(2) +
      "&pr_l_m=" +
      litePricePerMonth.toFixed(2) +
      "&d_p_m=" +
      properDiscountPerMonth.toFixed(2) +
      "&d_l_m=" +
      liteDiscountPerMonth.toFixed(2) +
      "&pr_p_e=" +
      properPricePerEmployee.toFixed(2) +
      "&pr_l_e=" +
      litePricePerEmployee.toFixed(2) +
      "&p_c=" +
      properCoursePerYear +
      "&p_o=" +
      properOneOffPerYear +
      "&l_o=" +
      liteOneOffPerYear +
      "&p_c_p=" +
      properPercentCourses.toFixed();

    const formData = {
      firstname: this.state.firstName,
      company: this.state.company,
      companysize: this.state.employees,
      email: this.state.email,
      policies:
        "MH day: " +
        this.state.mentalHealthDay +
        " pulse: " +
        this.state.transparentScore +
        " holidayPledge: " +
        this.state.holidayPledge +
        " chatSystem: " +
        this.state.chatSystem +
        " languages uk: " +
        this.state.basedUK +
        " europe: " +
        this.state.basedEurope +
        " us: " +
        this.state.basedUS +
        " row: " +
        this.state.basedRoW,
    };

    setTimeout(function() {

        window.open(URL);

    }, 3000);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Quote | Spill</title>
          <meta
            name="description"
            content="Spill lets you message your designated counsellor every day. Affordable talking therapy whenever and wherever you need it."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <QuoteNavigationBar currentDiscount={this.state.currentDiscount} />

        <FullSizeSection>
          <CenteredSection>
            <ProgressBarOuter>
              <ProgressBarInner
                progress={((this.state.stage + 1) / 9) * 100 + "%"}
              />
            </ProgressBarOuter>
            {this.state.stage == 1 ? (
              <Wrapper>
                <SectionTitle centered>
                  How many employees are in your company?
                </SectionTitle>
                <Form>
                  <Input
                    placeholder="Number of employees"
                    type="number"
                    value={this.state.employees}
                    onChange={this.onChangeEmployees}
                    title="employees"
                    aria-label="employees"
                  />
                </Form>

                <CenteredDiv>
                  <FillButton
                    text="next"
                    borderColor={theme.colors.primary.normal}
                    fontColor={theme.colors.blue.normal}
                    action={(event) => {
                      this.submitForm(1);
                    }}
                    disabledProperty={this.state.disabledProperty}
                  />
                </CenteredDiv>
                {this.state.error && (
                  <ErrorMessage centered>{this.state.formError}</ErrorMessage>
                )}
              </Wrapper>
            ) : this.state.stage == 2 ? (
              <Wrapper>
                <Wrapper>
                  <SectionTitle centered>
                    Which company chat system do you use?
                  </SectionTitle>
                  <ExtraBigForm>
                    <SmallBorderButton
                      fontColor={theme.colors.blue.normal}
                      isSelected={this.state.slackToggle}
                      name="Slack"
                      onClick={(event) => {
                        this.toggleValue(event, "1Toggle");
                      }}
                    >
                      Slack
                    </SmallBorderButton>
                    <SmallBorderButton
                      fontColor={theme.colors.blue.normal}
                      isSelected={this.state.teamsToggle}
                      name="MSTeams"
                      onClick={(event) => {
                        this.toggleValue(event, "2Toggle");
                      }}
                    >
                      MS Teams
                    </SmallBorderButton>
                    <SmallBorderButton
                      isSelected={this.state.otherToggle}
                      fontColor={theme.colors.blue.normal}
                      name="Other"
                      onClick={(event) => {
                        this.toggleValue(event, "3Toggle");
                      }}
                    >
                      Other
                    </SmallBorderButton>
                  </ExtraBigForm>

                  <CenteredDiv>
                    <FillButton
                      text="next"
                      borderColor={theme.colors.primary.normal}
                      fontColor={theme.colors.blue.normal}
                      action={(event) => {
                        this.submitForm(2);
                      }}
                      disabledProperty={this.state.disabledProperty}
                    />
                  </CenteredDiv>
                </Wrapper>
              </Wrapper>
            ) : this.state.stage == 3 ? (
              <Wrapper>
                <Wrapper>
                  <SectionTitle centered>
                    Where are your employees based?
                  </SectionTitle>
                  <Paragraph centered>Choose all that apply.</Paragraph>
                  <ExtraBigForm>
                    <SmallBorderButton
                      fontColor={theme.colors.blue.normal}
                      isSelected={this.state.basedUK}
                      name="UK"
                      onClick={(event) => {
                        this.toggleValue(event, "UKToggle");
                      }}
                    >
                      UK
                    </SmallBorderButton>
                    <SmallBorderButton
                      fontColor={theme.colors.blue.normal}
                      isSelected={this.state.basedEurope}
                      name="Europe"
                      onClick={(event) => {
                        this.toggleValue(event, "EuropeToggle");
                      }}
                    >
                      Europe
                    </SmallBorderButton>
                    <SmallBorderButton
                      isSelected={this.state.basedUS}
                      fontColor={theme.colors.blue.normal}
                      name="US"
                      onClick={(event) => {
                        this.toggleValue(event, "USToggle");
                      }}
                    >
                      US
                    </SmallBorderButton>
                    <SmallBorderButton
                      isSelected={this.state.basedRoW}
                      fontColor={theme.colors.blue.normal}
                      name="RoW"
                      onClick={(event) => {
                        this.toggleValue(event, "RoWToggle");
                      }}
                    >
                      Rest of world
                    </SmallBorderButton>
                  </ExtraBigForm>

                  <CenteredDiv>
                    <FillButton
                      text="next"
                      borderColor={theme.colors.primary.normal}
                      fontColor={theme.colors.blue.normal}
                      action={(event) => {
                        this.submitForm(3);
                      }}
                      disabledProperty={this.state.disabledProperty}
                    />
                  </CenteredDiv>
                </Wrapper>
              </Wrapper>
            ) : this.state.stage == 4 ? (
              <Wrapper>
                <Wrapper>
                  <SectionTitle centered>
                    Spill becomes cheaper for companies that opt in to better
                    practices and policies.{" "}
                  </SectionTitle>
                  <Paragraph centered>
                    Our mission is to make workplaces more emotionally
                    considerate, so we encourage positive social change through
                    our pricing model.
                  </Paragraph>

                  <CenteredDiv>
                    <ButtonWrapper>
                      <FillButton
                        text="next"
                        borderColor={theme.colors.primary.normal}
                        fontColor={theme.colors.blue.normal}
                        action={(event) => {
                          this.submitForm(4);
                        }}
                        disabledProperty={this.state.disabledProperty}
                      />
                    </ButtonWrapper>
                  </CenteredDiv>
                </Wrapper>
              </Wrapper>
            ) : this.state.stage == 5 ? (
              <Wrapper>
                <Wrapper>
                  <SectionTitle centered>
                    Do you offer (or want to offer) your team mental health
                    days?
                  </SectionTitle>
                  <Paragraph centered>
                    We can spot people who are struggling with the weekly
                    check-in and point them towards taking a mental health day.
                  </Paragraph>
                  <GreenParagraph centered>(8% discount)</GreenParagraph>
                  <BigForm>
                    <BigBorderButton
                      fontColor={theme.colors.blue.normal}
                      isSelected={this.state.yesToggle}
                      name="mentalHealthDay"
                      onClick={(event) => {
                        this.toggleValue(event, "yesToggle");
                      }}
                    >
                      Yes
                    </BigBorderButton>
                    <BigBorderButton
                      isSelected={this.state.noToggle}
                      fontColor={theme.colors.blue.normal}
                      name="mentalHealthDay"
                      onClick={(event) => {
                        this.toggleValue(event, "noToggle");
                      }}
                    >
                      No
                    </BigBorderButton>
                  </BigForm>

                  <CenteredDiv>
                    <FillButton
                      text="next"
                      borderColor={theme.colors.primary.normal}
                      fontColor={theme.colors.blue.normal}
                      action={(event) => {
                        this.submitForm(5);
                      }}
                      disabledProperty={this.state.disabledProperty}
                    />
                  </CenteredDiv>
                </Wrapper>
              </Wrapper>
            ) : this.state.stage == 6 ? (
              <Wrapper>
                <Wrapper>
                  <SectionTitle centered>
                    Do you want Spill to check in on how people are feeling?
                  </SectionTitle>
                  <Paragraph centered>
                    Spill will nudge people to book therapy when we notice a drop in mood.
                  </Paragraph>
                  <GreenParagraph centered>(5% discount)</GreenParagraph>
                  <BigForm>
                    <BigBorderButton
                      fontColor={theme.colors.blue.normal}
                      isSelected={this.state.yesToggle}
                      name="transparentScore"
                      onClick={(event) => {
                        this.toggleValue(event, "yesToggle");
                      }}
                    >
                      Yes
                    </BigBorderButton>
                    <BigBorderButton
                      isSelected={this.state.noToggle}
                      fontColor={theme.colors.blue.normal}
                      name="transparentScore"
                      onClick={(event) => {
                        this.toggleValue(event, "noToggle");
                      }}
                    >
                      No
                    </BigBorderButton>
                  </BigForm>

                  <CenteredDiv>
                    <FillButton
                      text="next"
                      borderColor={theme.colors.primary.normal}
                      fontColor={theme.colors.blue.normal}
                      action={(event) => {
                        this.submitForm(6);
                      }}
                      disabledProperty={this.state.disabledProperty}
                    />
                  </CenteredDiv>
                </Wrapper>
              </Wrapper>
            ) : this.state.stage == 7 ? (
              <Wrapper>
                <Wrapper>
                  <SectionTitle centered>
                    Do you want to sign up to{" "}
                    <a
                      href="https://www.spill.chat/blog/tackling-the-time-off-problem-in-tech-companies"
                      target="_blank"
                    >
                      Spill's Holiday Pledge
                    </a>
                    ? 🏖
                  </SectionTitle>
                  <Paragraph centered>
                    Spill's Holiday Pledge asks a company to commit to two
                    actions that encourage people to take their holiday
                    allowance.
                  </Paragraph>
                  <GreenParagraph centered>(7% discount)</GreenParagraph>
                  <BigForm>
                    <BigBorderButton
                      fontColor={theme.colors.blue.normal}
                      isSelected={this.state.yesToggle}
                      name="holidayPledge"
                      onClick={(event) => {
                        this.toggleValue(event, "yesToggle");
                      }}
                    >
                      Yes
                    </BigBorderButton>
                    <BigBorderButton
                      isSelected={this.state.noToggle}
                      fontColor={theme.colors.blue.normal}
                      name="letThemGoSurfing"
                      onClick={(event) => {
                        this.toggleValue(event, "noToggle");
                      }}
                    >
                      No
                    </BigBorderButton>
                  </BigForm>

                  <CenteredDiv>
                    <FillButton
                      text="next"
                      borderColor={theme.colors.primary.normal}
                      fontColor={theme.colors.blue.normal}
                      action={(event) => {
                        this.submitForm(7);
                      }}
                      disabledProperty={this.state.disabledProperty}
                    />
                  </CenteredDiv>
                </Wrapper>
              </Wrapper>
            ) : this.state.stage == "final" ? (
              <Wrapper>
                <SectionTitle centered>Calculating quote...</SectionTitle>
                <DrawingQuote src={Waiting} alt="Waiting Image" />
              </Wrapper>
            ) : (
              <Wrapper>
                <SectionTitle centered>Refresh page...</SectionTitle>
              </Wrapper>
            )}
          </CenteredSection>
        </FullSizeSection>
      </Fragment>
    );
  }
}

export default Pricing;
