import styled from "styled-components";

export const Section = styled.div`
  display: grid;
  grid-template-columns: 100%;
  background-color: ${props => props.backgroundColor};
  padding: 2rem 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 5rem 10rem;
    height: 50vh;
    overflow: hidden;
  }
`;

export const HalfSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  align-items: start;
  justify-content: start;
  align-content: center;
  grid-row-gap: 1rem;
  @media (min-width: 768px) {
    grid-column: ${props => props.gridColumn};
    grid-row: 1;
    justify-self: ${props => props.justifySelf};
    width: ${props => props.width};
  }
`;

export const PageTitle = styled.h1`
  font-family: MarkPro-Bold;
  color: black;
  font-size: 2.5rem;
  padding-bottom: 1rem;
`;

export const PageDescription = styled.p`
  font-family: Lato;
  color: grey;
  padding-bottom: 3rem;
  line-height: 1.5em;
`;

export const SubTitle = styled.h3`
  font-family: Lato;
  font-weight: 800;
  color: grey;
  text-transform: uppercase;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  letter-spacing: 0.05rem;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 3rem 0.5rem;
  height: 100%;
`;

export const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.gridColumn};
  /* justify-self: start; */
`;

export const ElementTitle = styled.h4`
  font-family: Lato;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  color: #333333;
`;

export const PayNowButton = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.8rem;
  border-radius: 0.5rem;
  border: 0.08rem solid grey;
  cursor: pointer;
  color: grey;
  background-color: white;
  &:hover {
    color: black;
    border-color: black;
  }
`;
