import React, { Fragment, Component } from "react";
import { Helmet } from "react-helmet";
import { SectionTitle, Paragraph } from "../../style/text";
import { Container, LongCopy, Input, EmailInput } from "./style";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

    render() {
    return (

  <Fragment>
    <Helmet>
      <title>404 | Spill</title>
      <meta
        name="description"
        content="Spill lets you message your designated counsellor every day. Affordable talking therapy whenever and wherever you need it."
      />
      <meta
        name="keywords"
        content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
      />
    </Helmet>
    <Container>
      <SectionTitle>Ask a therapst a question to help with the emotional side of COVID-19.</SectionTitle>
      <Paragraph>There's a lot going on in the world right now, and it's easy to feel out of control and uncertain.

That's why we're opening up one of Spill's core services -- the ability to ask a question to a therapist via message -- to anyone and everyone: because the emotional fallout of COVID-19 affects us all.

We're posting all the answers to <a href="">our master resource</a>, as they might be applicable to lots of people
.</Paragraph>

<Input
        placeholder="Write about how you are feeling today                                                                                                                                                                                                                                                                                                                    You can press Ctrl and Enter to post                                                                                                                                                                                                                                                                                          And there is a minimum of 280 characters"
        value={this.state.text} 
        onChange={this.textChange}
        />

        <EmailInput
                    placeholder="Email Address"
                    type="email"
                    value={this.state.name}
                    onChange={this.onChangeName}
                    title="email"
                    aria-label="email"
                  />
      
    </Container>
  </Fragment>
    );
  }
}

export default Question;
