import React, { Fragment } from "react";
import ReactGA from "react-ga";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe
} from "react-stripe-elements";
import { withTheme } from "styled-components";

import { BorderButton } from "../../components/buttons/index";

import {
  Form,
  FormElement,
  ElementTitle,
  PayNowButton,
  Section,
  PageTitle,
  PageDescription,
  SubTitle
} from "./style";
import { Paragraph } from "../../style/text";

const handleBlur = () => {
  console.log("[blur]");
};
const handleChange = change => {
  console.log("[change]", change);
};
const handleClick = () => {
  console.log("[click]");
};
const handleFocus = () => {
  console.log("[focus]");
};
const handleReady = () => {
  console.log("[ready]");
};

const createOptions = fontSize => {
  return {
    style: {
      base: {
        fontSize,
        color: "grey",
        letterSpacing: "0.025em",
        fontFamily: "Lato, Source Code Pro, Menlo, monospace",
        "::placeholder": {
          color: "grey"
        }
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };
};

class PaymentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      paid: false,
      loading: false,
      error: false,
      plan: "plan_E4KrOqQ41qh77j",
      referral: "",
      trial: 20
    };
  }

  componentDidMount() {
    //console.log(this.state.trial)
  }

  handleSubmit = ev => {
    console.log(this.state.trial);
    ev.preventDefault();
    this.setState({ loading: true });
    this.props.stripe.createSource().then(result => {
      const { email } = this.props;
      fetch(
        `/api/stripe-customer?email=${email}&plan=plan_G7RGgnX4ObViF1&trial=${
          this.state.trial
        }`,
        {
          method: "Post",
          body: JSON.stringify(result),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          if (response.status === 200) {
            this.setState({ paid: true });
            ReactGA.event({
              category: "User",
              action: "paid"
            });
          } else if (response.status === 201) {
            response.json().then(resu => {
              this.setState({
                loading: false,
                errorMessage: resu.message,
                error: true
              });
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  render() {
    return (
      <Fragment>
        {!this.state.paid ? (
          <Form className="fade-in" onSubmit={this.handleSubmit}>
            <FormElement gridColumn="1 / span 3">
              <ElementTitle>Card number</ElementTitle>
              <CardNumberElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                {...createOptions(this.props.fontSize)}
              />
            </FormElement>
            <FormElement>
              <ElementTitle>Expiration date</ElementTitle>
              <CardExpiryElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                {...createOptions(this.props.fontSize)}
              />
            </FormElement>
            <FormElement>
              <ElementTitle>CVC</ElementTitle>
              <CardCVCElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                {...createOptions(this.props.fontSize)}
              />
            </FormElement>
            <FormElement>
              <ElementTitle>Postcode</ElementTitle>
              <PostalCodeElement
                placeholder="AB12 3CD"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                {...createOptions(this.props.fontSize)}
              />
            </FormElement>
            {!this.state.loading ? (
              // <FormElement>
              <BorderButton
                type="submit"
                text="submit"
                borderColor={this.props.theme.colors.blue.normal}
                fontColor={this.props.theme.colors.blue.normal}
              />
            ) : (
              <p>Loading...</p>
            )}
            {this.state.error && <p>{this.state.errorMessage}</p>}
          </Form>
        ) : (
          <div>
            <PageTitle>Your journey begins.</PageTitle>
            <Paragraph>But the first steps are easy.</Paragraph>
            <br />
            <Paragraph>
              1. download and install "Spill" from{" "}
              <a href="https://play.google.com/store/apps/details?id=com.spillforwork&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                Google Play
              </a>{" "}
              / the{" "}
              <a href="https://itunes.apple.com/us/app/spill-for-work/id1382601306?ls=1&mt=8">
                App Store
              </a>
              <br />
              2. wait for the Spill team to be in touch via email shortly (within a day).
              <br />
            </Paragraph>
            <br />
            <Paragraph className="header-lead">
              If you have any problems then just drop us a line on{" "}
              <a href="mailto:maria@spill.chat">maria@spill.chat</a>
            </Paragraph>
          </div>
        )}
      </Fragment>
    );
  }
}
export default injectStripe(withTheme(PaymentForm));
