import React, { Fragment } from "react";
import ReactGA from "react-ga";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe,
} from "react-stripe-elements";
import { withTheme } from "styled-components";

import { BorderButton } from "../../components/buttons/index";

import {
  Form,
  FormElement,
  ElementTitle,
  PayNowButton,
  Section,
  PageTitle,
  PageDescription,
  SubTitle,
  EmailInput,
} from "./style";
import { Paragraph } from "../../style/text";

const handleBlur = () => {
  console.log("[blur]");
};
const handleChange = (change) => {
  console.log("[change]", change);
};
const handleClick = () => {
  console.log("[click]");
};
const handleFocus = () => {
  console.log("[focus]");
};
const handleReady = () => {
  console.log("[ready]");
};

const createOptions = (fontSize) => {
  return {
    style: {
      base: {
        fontSize,
        color: "grey",
        letterSpacing: "0.025em",
        fontFamily: "Lato, Source Code Pro, Menlo, monospace",
        "::placeholder": {
          color: "grey",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

class PaymentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      paid: false,
      loading: false,
      error: false,
      plan: "plan_E4KrOqQ41qh77j",
      referral: "",
      trial: 20,
      email: "",
    };
  }

  componentDidMount() {
    //console.log(this.state.trial)
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = (ev) => {
    console.log(this.state.trial);
    ev.preventDefault();
    this.setState({ loading: true });
    this.props.stripe.createSource().then((result) => {
      fetch(`/api/stripe-customer-invoicing?email=${this.state.email}`, {
        method: "Post",
        body: JSON.stringify(result),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({ paid: true });
          } else if (response.status === 201) {
            response.json().then((resu) => {
              this.setState({
                loading: false,
                errorMessage: resu.message,
                error: true,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  render() {
    const CARD_OPTIONS = {
      style: {
        base: {
          iconColor: "#c4f0ff",
          color: "#fff",
          borderBottom: "5px solid red",
          fontWeight: 500,
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "18px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": { color: "#fce883" },
          "::placeholder": { color: "#87bbfd" },
        },
      },
    };
    return (
      <Fragment>
        {!this.state.paid ? (
          <Form className="fade-in" onSubmit={this.handleSubmit}>
            <FormElement gridColumn="1 / span 3">
              <ElementTitle>Email</ElementTitle>
              <EmailInput
                placeholder="email@example.com"
                type="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                title="email"
                aria-label="email"
              />
            </FormElement>
            <FormElement gridColumn="1 / span 3">
              <ElementTitle>Card number</ElementTitle>
              <CardNumberElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                options={CARD_OPTIONS}
              />
            </FormElement>
            <FormElement>
              <ElementTitle>Expiration date</ElementTitle>
              <CardExpiryElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                options={CARD_OPTIONS}
              />
            </FormElement>
            <FormElement>
              <ElementTitle>CVC</ElementTitle>
              <CardCVCElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                options={CARD_OPTIONS}
              />
            </FormElement>
            <FormElement>
              <ElementTitle>Postcode</ElementTitle>
              <PostalCodeElement
                placeholder="AB12 3CD"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                options={CARD_OPTIONS}
              />
            </FormElement>
            {!this.state.loading ? (
              // <FormElement>
              <BorderButton
                type="submit"
                text="submit"
                borderColor={this.props.theme.colors.blue.normal}
                fontColor={this.props.theme.colors.blue.normal}
              />
            ) : (
              <p>Loading...</p>
            )}
            {this.state.error && <p>{this.state.errorMessage}</p>}
          </Form>
        ) : (
          <div>
            <PageTitle>You're all set up.</PageTitle>
            <Paragraph>
              Someone from the Spill team will reach out to book in your launch
              talk.
            </Paragraph>
          </div>
        )}
      </Fragment>
    );
  }
}
export default injectStripe(withTheme(PaymentForm));
