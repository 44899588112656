import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Navigation,
  Logo,
  NavItems,
  NavItem,
  NavLink,
  MenuButton,
  MenuDropdown,
  TransparentNavLink,
  TransparentNavLogo,
  TransparentNavMenuButton,
  TransparentNavMenuDropdown,
  TransparentNavigation,
  Wrapper,
  LogoStyle,
} from "./style";
import SpillLogoBlue from "../../assets/logo/logo-blue.png";
import SpillLogoLightBlue from "../../assets/logo/logo-xlight-blue.png";
import { SectionTitle, Paragraph, SmallParagraph } from "../../style/text";

export class TransparentNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      position: false,
    };
    this.handleDropdownOnLargeScreen = this.handleDropdownOnLargeScreen.bind(
      this
    );
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleDropdownOnLargeScreen);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleDropdownOnLargeScreen);
  }

  toggleMenu() {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
      setTimeout(() => {
        this.setState({ position: false });
      }, 120);
    } else {
      this.setState({ showMenu: true, position: true });
    }
  }

  handleDropdownOnLargeScreen() {
    if (window.outerWidth >= 1024) {
      this.setState({ showMenu: false });
    }
  }

  render() {
    return (
      <Fragment>
        <TransparentNavigation showNav>
          <LogoStyle>
            <Logo src={SpillLogoLightBlue} alt="Spill logo" />
          </LogoStyle>
        </TransparentNavigation>
      </Fragment>
    );
  }
}

export class WhiteNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      position: false,
    };
    this.handleDropdownOnLargeScreen = this.handleDropdownOnLargeScreen.bind(
      this
    );
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleDropdownOnLargeScreen);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleDropdownOnLargeScreen);
  }

  toggleMenu() {
    if (this.state.showMenu) {
      document.body.style.overflow = "auto"; // allow scrolling again
      this.setState({ showMenu: false });
      setTimeout(() => {
        this.setState({ position: false });
      }, 120);
    } else {
      document.body.style.overflow = "hidden"; // stop scrolling in background when menu open
      this.setState({ showMenu: true, position: true });
    }
  }

  handleDropdownOnLargeScreen() {
    if (window.outerWidth >= 1024) {
      this.setState({ showMenu: false });
    }
  }

  render() {
    return (
      <Wrapper>
        <Navigation
          showNav={
            typeof this.props.showNav !== "undefined"
              ? this.props.showNav
              : true
          }
        >
          <Link
            to="https://www.spill.chatt/"
            onClick={() => {
              window.scrollTo(0, 0);
              this.setState({ showMenu: false });
            }}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Logo src={SpillLogoBlue} alt="Spill logo" />
          </Link>
        </Navigation>
      </Wrapper>
    );
  }
}

export class QuoteNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      position: false,
    };
    this.handleDropdownOnLargeScreen = this.handleDropdownOnLargeScreen.bind(
      this
    );
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleDropdownOnLargeScreen);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleDropdownOnLargeScreen);
  }

  toggleMenu() {
    if (this.state.showMenu) {
      document.body.style.overflow = "auto"; // allow scrolling again
      this.setState({ showMenu: false });
      setTimeout(() => {
        this.setState({ position: false });
      }, 120);
    } else {
      document.body.style.overflow = "hidden"; // stop scrolling in background when menu open
      this.setState({ showMenu: true, position: true });
    }
  }

  handleDropdownOnLargeScreen() {
    if (window.outerWidth >= 1024) {
      this.setState({ showMenu: false });
    }
  }

  render() {
    return (
      <Wrapper>
        <Navigation
          showNav={
            typeof this.props.showNav !== "undefined"
              ? this.props.showNav
              : true
          }
        >
          <a
            href="https://www.spill.chat"
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Logo src={SpillLogoBlue} alt="Spill logo" />
          </a>
          <NavItems>
            <NavItem>
              <Paragraph>
                Current Discount: {this.props.currentDiscount}%
              </Paragraph>
            </NavItem>
          </NavItems>
        </Navigation>
      </Wrapper>
    );
  }
}
