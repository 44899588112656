import React, { Component, Fragment } from "react";
import ReactSVG from "react-svg";
import { Helmet } from "react-helmet";

import { ExclamationMarkIcon } from "../../assets/icons";
import { BorderButton } from "../../components/buttons/index";
import { CenteredSection } from "../../style/base-components";
import { SectionTitle, Paragraph, SmallParagraph } from "../../style/text";
import theme from "../../style/theme";
import {
  ThreeColumns,
  Underline,
  Typeform,
  Input,
  Wrapper,
  LinkText,
  LinkTextTertiary,
  Stage,
  Reason,
  Description,
  IconWrapper,
  ErrorMessage,
  StoreContainer,
  StoreImg,
  ButtonContainer,
  CenteredDiv,
} from "./style";
import { ReactTypeformEmbed } from "react-typeform-embed";
import IOSStore from "../../assets/IOSAPPSTORE.svg";

class Portal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: -1,
      email: "",
      firstName: "",
      password: "",
      error: false,
      formError: "",
      userType: "user",
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
  }
  componentDidMount() {}

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeName(event) {
    this.setState({ firstName: event.target.value });
  }

  onChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  formValidation() {
    if (this.state.firstName.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter your name",
      });
      return false;
    }
    if (this.state.email.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter a valid email",
      });
      return false;
    }
    if (this.state.password.trim().length < 6) {
      this.setState({
        error: true,
        formError: "Please enter a password greater than 6 characters",
      });
      return false;
    }
    return true;
  }

  submitForm(event) {
    console.log(event);
    this.props.history.push("/sign-up/" + this.state.email);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Start | Spill</title>
          <meta
            name="description"
            content="Spill lets you message your designated counsellor every day. Affordable talking therapy whenever and wherever you need it."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>

        <Typeform>
          <ReactTypeformEmbed url="https://spillchat.typeform.com/to/WYQ1ZQu7" />
        </Typeform>
      </Fragment>
    );
  }
}

export default Portal;
