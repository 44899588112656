import React, { Fragment } from "react";
import "babel-polyfill";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

ReactGA.initialize("UA-118977695-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

import Home from "./pages/home/index";
import OneOff from "./pages/oneOff/index";
import FreeTherapyHR from "./pages/freeTherapyHR/index";
import TeamTherapyHR from "./pages/teamTherapyHR/index";
import OneOffBen from "./pages/oneOffBen/index";
import OneOffCovid from "./pages/oneOffCovid/index";
import Question from "./pages/question/index";
import { WhiteNavigationBar } from "./components/navigation/index";
import Start from "./pages/start/index";
import Pricing from "./pages/pricing/index";
import Portal from "./pages/portal/index";
import PortalDD from "./pages/portalDD/index";
import PortalHyve from "./pages/portalHyve/index";
import PortalVorboss from "./pages/portalVorboss/index";
import Portal2m from "./pages/portal2m/index";
import Billing from "./pages/Billing/index";
import Invoicing from "./pages/Invoicing/index";
import InvoicingSMB from "./pages/InvoicingSMB/index";
import NotFound from "./pages/404/index";
import Startups from "./pages/startups/index";
import ChristmasCover from "./pages/christmasCover/index";
import LockdownCover from "./pages/lockdownCover/index";
import AugustCover from "./pages/augustCover/index";
import Resources from "./pages/resources/index";
import ResourcesDD from "./pages/resourcesDD/index";
import Resources2m from "./pages/resources2m/index";
import ResourcesMoonpig from "./pages/resourcesMoonpig/index";
import ResourcesIwoca from "./pages/resourcesIwoca/index";
import ResourcesHyve from "./pages/resourcesHyve/index";
import ResourcesVorboss from "./pages/resourcesVorboss/index";
import Tools from "./pages/tools/index";
import Reading from "./pages/reading/index";
import PartnerSignupGFS from "./pages/partnerSignupGFS/index";

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={Pricing} />
      <Fragment>
        <Switch>
          <Route
            exact
            path="/ask-a-therapist-a-question"
            component={Question}
          />
          <Route exact path="/spill-for-startups" component={Startups} />
          <Route exact path="/lockdown-cover" component={LockdownCover} />
          <Route exact path="/august-cover" component={AugustCover} />
          <Route exact path="/s" component={FreeTherapyHR} />
          <Route exact path="/t" component={TeamTherapyHR} />
          <Route exact path="/start" component={Start} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/portal" component={Portal} />
          <Route exact path="/2m/portal" component={Portal2m} />
          <Route exact path="/hyve/portal" component={PortalHyve} />
          <Route exact path="/vorboss/portal" component={PortalVorboss} />
          <Route exact path="/resources" component={Resources} />
          <Route exact path="/2m" component={Resources2m} />
          <Route exact path="/moonpig" component={ResourcesMoonpig} />
          <Route exact path="/iwoca" component={ResourcesIwoca} />
          <Route exact path="/hyve" component={ResourcesHyve} />
          <Route exact path="/vorboss" component={ResourcesVorboss} />
          <Route exact path="/tools" component={Tools} />
          <Route exact path="/reading" component={Reading} />
          <Route path="/sign-up/:email" component={Billing} />
          <Route path="/invoicing" component={Invoicing} />
          <Route path="/invoicing-smb" component={InvoicingSMB} />
          <Route exact path="/404" component={NotFound} />
          <Route path="/*" render={Pricing} />
        </Switch>
      </Fragment>
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
