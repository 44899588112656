import * as React from "react";
export default function IntakeForm({ setHubspotIdentifier }) {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  React.useEffect(() => {
    const script = document.createElement("script");
    script.onload = () => setHasLoaded(true);
    script.src = "//js.hsforms.net/forms/v2.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  React.useEffect(() => {
    if (hasLoaded) {
      window.hbspt.forms.create({
        portalId: "8481055",
        formId: "fc3f67d2-607c-41f3-b649-c3830bae8771",
        target: "#hubspot-form-container",
      });
    }
  }, [hasLoaded]);
  return <div id="hubspot-form-container" className="container mx-auto py-8" />;
}
