import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import queryString from 'query-string'

import {
  TwoColumnSection,
  CopySection,
  Drawing,
  CenteredSection,
  LinkText
} from "../../style/base-components";
import {
  SectionTitle,
  SectionTitleCTA,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading
} from "../../style/text";
import {
  TopSection,
  Title,
  Bold,
  ButtonContainer,
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  ListContainer,
  ListGrid,
  ListTitle,
  ListItem,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  CompanyGrid,
  CompanyLogo,
  PressGrid,
  PressLogo,
  Form,
  Input,
  Wrapper,
  CenteredDiv,
  MultiLineInput,

} from "./style";


import ConversationCards from "../../assets/images/conversation-cards.png";
import VideoTherapy from "../../assets/images/therapy.png";
import Therapist from "../../assets/images/therapist.png";
import ChairRocket884 from "../../assets/images/chair-rocket@884.png";
import ChairRocket1059 from "../../assets/images/chair-rocket@1059.png";
import ChairRocket1400 from "../../assets/images/chair-rocket@1400.png";

import LifeRing200 from "../../assets/images/life-ring@200.png";
import LifeRing477 from "../../assets/images/life-ring@477.png";
import LifeRing692 from "../../assets/images/life-ring@692.png";
import LifeRing873 from "../../assets/images/life-ring@873.png";
import LifeRing1043 from "../../assets/images/life-ring@1043.png";
import LifeRing1400 from "../../assets/images/life-ring@1400.png";

import Toothpaste200 from "../../assets/images/toothpaste@200.png";
import Toothpaste482 from "../../assets/images/toothpaste@482.png";
import Toothpaste696 from "../../assets/images/toothpaste@696.png";
import Toothpaste880 from "../../assets/images/toothpaste@880.png";
import Toothpaste1061 from "../../assets/images/toothpaste@1061.png";
import Toothpaste1400 from "../../assets/images/toothpaste@1400.png";

import Trophy200 from "../../assets/images/trophy@200.png";
import Trophy467 from "../../assets/images/trophy@467.png";
import Trophy674 from "../../assets/images/trophy@674.png";
import Trophy854 from "../../assets/images/trophy@854.png";
import Trophy1027 from "../../assets/images/trophy@1027.png";
import Trophy1400 from "../../assets/images/trophy@1400.png";

import EarsInAJar from "../../assets/images/ears-in-a-jar.png";
import MonzoLogo from "../../assets/companyLogos/monzo.png";
import CharlieLogo from "../../assets/companyLogos/charlie.png";
import RightmoveLogo from "../../assets/companyLogos/rightmove.png";
import TimeoutLogo from "../../assets/companyLogos/timeout.png";

import HargreavesLansdownLogo200 from "../../assets/companyLogos/hargreaves-lansdown@200.png";
import HargreavesLansdownLogo1280 from "../../assets/companyLogos/hargreaves-lansdown@1280.png";

import EveningStandardLogo from "../../assets/pressLogos/evening-standard.png";
import GraziaLogo from "../../assets/pressLogos/grazia.png";
import StylistLogo from "../../assets/pressLogos/stylist.png";
import TheIndependentLogo from "../../assets/pressLogos/the-independent.png";
import TheTimesLogo from "../../assets/pressLogos/the-times.png";

import {
  LightBulbIcon,
  LightningIcon,
  CellPhoneIcon,
  ConcentricCircleIcon,
  VisibilityIcon,
  LineChartIcon
} from "../../assets/icons/index";

import Separator from "../../components/separator/index";
import {
  GetSpill,
  GetUpdates,
  WhiteCTA,
  StayUpToDate
} from "../../components/callToAction/index";
import {
  TransparentNavigationBar,
  WhiteNavigationBar
} from "../../components/navigation/index";
import {
  FillButton,
  BorderButton,
  TextButton
} from "../../components/buttons/index";
import theme from "../../style/theme";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: "",
      showNav: false,
      formSuccess: false
    };
    this.topSectionRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeGift = this.onChangeGift.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.topSectionHeight = this.topSectionRef.current.clientHeight;
    window.addEventListener("scroll", this.handleScroll);
    const hash = queryString.parse(this.props.location.hash);
    const urlParams = queryString.parse(this.props.location.search)
    this.setState({ campaign: hash.campaign, companyName: urlParams.team });
    console.log(this.props.location.pathname)
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeGift(event) {
    this.setState({ gift: event.target.value });
  }

  submitForm(event) {

    const formData = {
      name: this.state.name,
      companyName: this.state.companyName,
      email: this.state.email,
      subject: "New Free Session"
    };

    fetch("/api/new-gift", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        console.log('cool')
        this.setState({formSuccess: true})
      })
      .catch(console.log);

    console.log(formData)
    /*this.setState({ error: false, formError: "" });
    event.preventDefault();
    if (!this.formValidation()) return;
    const formData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      firstName: this.state.firstName
    };
    fetch("/api/create-user", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          if (this.state.email.includes("northumbria.ac.uk"))
            this.setState({ stage: 3 });
          else this.setState({ stage: 2 });
        } else {
          res.json().then(body => {
            this.setState({ formError: body.message, error: true });
          });
        }
      })
      .catch(console.log);*/
  }



  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;
    this.setState({
      showNav: scrollY > this.topSectionHeight
    });
  }


  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Free therapy session | Spill</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <TransparentNavigationBar />
        <TopSection ref={this.topSectionRef}>
          
            <React.Fragment>
              <Title>
              {"Free falafel!"}
                {
                  "\nWait, no. Free therapy!"
                }
              </Title>
            </React.Fragment>
        </TopSection>



        <CenteredSection>
          <SectionTitle centered>
            Claim your free video therapy session
          </SectionTitle>
            
            <ListItem>
                <ItemDescription>
                  Spill is a workplace mental health company on a mission to make therapy more accessible. We are offering people at {this.state.companyName} a free therapy sessions see what therapy is like on Spill.
                </ItemDescription>
            </ListItem>


        </CenteredSection>

        <Separator />

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>
              Try a video therapy session with Spill.
            </SectionTitle>
            <ThinParagraph>
              There is so much change happening right now that it's easy to forget the need to stop and check in with ourselves.

</ThinParagraph>
          </CopySection>
          <Fade>
            <Drawing src={VideoTherapy} alt="Video Therapy" />
          </Fade>
        </TwoColumnSection>

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>
              All of our counsellors are BACP or UKCP registered.
            </SectionTitle>
            <ThinParagraph>
              This means that they have to undertake three years of academic study and 100 clinically supervised hours. At Spill we have a five step hiring process. We accept less than 7% of applicants that apply; for context Oxford University has a 17% acceptance rate.
              </ThinParagraph>
          </CopySection>
          <Fade>
            <Drawing src={Therapist} alt="Talking Book" />
          </Fade>
        </TwoColumnSection>

        <Separator />

        <CenteredSection ref={this.sectionRef}>


          <Fragment>
            <SectionTitleCTA centered>Claim your free video therapy session.</SectionTitleCTA>
                 <Paragraph>
                  <br/>After you fill out the form we'll email you a link to book your session.</Paragraph>
                <Wrapper>
               
              <Form>

                  <Input
                    placeholder="First name"
                    type="text"
                    value={this.state.name}
                    onChange={this.onChangeName}
                    title="name"
                    aria-label="name"
                  />

                  <Input
                    placeholder="Email address"
                    type="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    title="emaiil"
                    aria-label="emal"
                  />

                
              </Form>
              {
              this.state.formSuccess === true ?
              <Paragraph centered>Great! We'll email you ASAP to get you set up.</Paragraph>
              :

              <CenteredDiv>
                <BorderButton
                  text="Claim Therapy Session"
                  borderColor={theme.colors.blue.normal}
                  fontColor={theme.colors.blue.normal}
                  action={event => {
                    this.submitForm(event);
                  }}
                />
                </CenteredDiv>
              }
          
              
                

                </Wrapper>


        
          </Fragment>
        </CenteredSection>
        <CenteredSection>
          
            <PressGrid>
              <PressLogo
                src={EveningStandardLogo}
                alt="Evening Standard logo"
              />
              <PressLogo src={GraziaLogo} alt="Grazia logo" />
              <PressLogo src={TheTimesLogo} alt="The Times logo" />
              <PressLogo src={TheIndependentLogo} alt="The Independent logo" />
              <PressLogo src={StylistLogo} alt="Stylist logo" />
            </PressGrid>
        </CenteredSection>


      </Fragment>
    );
  }
}

export default withRouter(Home);
