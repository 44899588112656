import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import {
  TwoColumnSection,
  CopySection,
  Drawing,
  CenteredSection,
  ShadowImg,
  Img,
  TitleSection,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  WhiteParagraph,
  LongSectionTitleNoPadding,
  ResourceLink,
} from "../../style/text";
import {
  TopSection,
  Title,
  Bold,
  ButtonContainer,
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  ListContainer,
  ListGrid,
  ListTitle,
  ListItem,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  CompanyGrid,
  CompanyLogo,
  PressGrid,
  PressLogo,
  Form,
  Input,
  Wrapper,
  CenteredDiv,
  CenteredCTADiv,
  MultiLineInput,
} from "./style";

import One from "../../assets/resources/1course.png";
import Two from "../../assets/resources/2one.png";
import Three from "../../assets/resources/3message.png";
import Four from "../../assets/resources/4reading.png";
import Five from "../../assets/resources/5tools.png";
import Six from "../../assets/resources/6manager.png";

import Passion from "../../assets/images/passion.png";
import Seedcamp from "../../assets/images/seedcamp.png";
import GFS from "../../assets/images/gfs.png";
import Kindred from "../../assets/images/kindred.png";

import EveningStandardLogo from "../../assets/pressLogos/evening-standard.png";
import GraziaLogo from "../../assets/pressLogos/grazia.png";
import StylistLogo from "../../assets/pressLogos/stylist.png";
import TheIndependentLogo from "../../assets/pressLogos/the-independent.png";
import TheTimesLogo from "../../assets/pressLogos/the-times.png";

import Separator from "../../components/separator/index";
import {
  GetSpill,
  GetUpdates,
  WhiteCTA,
  StayUpToDate,
} from "../../components/callToAction/index";
import {
  TransparentNavigationBar,
  WhiteNavigationBar,
} from "../../components/navigation/index";
import {
  FillButton,
  BorderButton,
  TextButton,
} from "../../components/buttons/index";
import theme from "../../style/theme";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      formSuccess: false,
    };
    this.sectionRef = React.createRef();
    this.topSectionRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeInvestor = this.onChangeInvestor.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onScrollDown = this.onScrollDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const hash = qs.parse(this.props.location.hash);
    console.log(this.props.location.pathname);
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeCompanyName(event) {
    this.setState({ companyName: event.target.value });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeInvestor(event) {
    this.setState({ investor: event.target.value });
  }

  submitForm(event) {
    const formData = {
      name: this.state.name,
      companyName: this.state.companyName,
      email: this.state.email,
      investor: this.state.investor,
      subject: "New Credit Claim",
    };

    fetch("/api/new-gift", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("cool");
        this.setState({ formSuccess: true });
      })
      .catch(console.log);

    console.log(formData);
    /*this.setState({ error: false, formError: "" });
    event.preventDefault();
    if (!this.formValidation()) return;
    const formData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      firstName: this.state.firstName
    };
    fetch("/api/create-user", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          if (this.state.email.includes("northumbria.ac.uk"))
            this.setState({ stage: 3 });
          else this.setState({ stage: 2 });
        } else {
          res.json().then(body => {
            this.setState({ formError: body.message, error: true });
          });
        }
      })
      .catch(console.log);*/
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;
    this.setState({
      showNav: scrollY > this.topSectionHeight,
    });
  }

  onScrollDown(event) {
    event.preventDefault();
    console.log(this.sectionRef);
    window.scrollTo({
      left: 0,
      top: this.sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div>
        <p>access removed</p>
      </div>
    );
  }
}

export default withRouter(Resources);
