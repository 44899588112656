import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import {
  TwoColumnSection,
  CopySection,
  Drawing,
  CenteredSection,
  ShadowImg,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  WhiteParagraph,
} from "../../style/text";
import {
  TopSection,
  Title,
  Bold,
  ButtonContainer,
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  ListContainer,
  ListGrid,
  ListTitle,
  ListItem,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  CompanyGrid,
  CompanyLogo,
  PressGrid,
  PressLogo,
  Form,
  Input,
  Wrapper,
  CenteredDiv,
  CenteredCTADiv,
  MultiLineInput,
} from "./style";

import Onboarding from "../../assets/images/onboarding.png";
import VideoTherapy from "../../assets/images/therapy.png";
import MainMenu from "../../assets/images/main-menu.png";

import Passion from "../../assets/images/passion.png";
import Seedcamp from "../../assets/images/seedcamp.png";
import Ada from "../../assets/images/ada.png";
import Kindred from "../../assets/images/kindred.png";

import EveningStandardLogo from "../../assets/pressLogos/evening-standard.png";
import GraziaLogo from "../../assets/pressLogos/grazia.png";
import StylistLogo from "../../assets/pressLogos/stylist.png";
import TheIndependentLogo from "../../assets/pressLogos/the-independent.png";
import TheTimesLogo from "../../assets/pressLogos/the-times.png";

import Separator from "../../components/separator/index";
import {
  GetSpill,
  GetUpdates,
  WhiteCTA,
  StayUpToDate,
} from "../../components/callToAction/index";
import {
  TransparentNavigationBar,
  WhiteNavigationBar,
} from "../../components/navigation/index";
import {
  FillButton,
  BorderButton,
  TextButton,
} from "../../components/buttons/index";
import theme from "../../style/theme";
import IntakeForm from "./form.js";

class Startups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      formSuccess: false,
    };
    this.sectionRef = React.createRef();
    this.topSectionRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeInvestor = this.onChangeInvestor.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onScrollDown = this.onScrollDown.bind(this);
  }

  componentDidMount() {
    this.topSectionHeight = this.topSectionRef.current.clientHeight;
    window.addEventListener("scroll", this.handleScroll);
    const hash = qs.parse(this.props.location.hash);
    console.log(this.props.location.pathname);
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeCompanyName(event) {
    this.setState({ companyName: event.target.value });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeInvestor(event) {
    this.setState({ investor: event.target.value });
  }

  submitForm(event) {
    const formData = {
      name: this.state.name,
      companyName: this.state.companyName,
      email: this.state.email,
      investor: this.state.investor,
      subject: "New Credit Claim",
    };

    fetch("/api/new-gift", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("cool");
        this.setState({ formSuccess: true });
      })
      .catch(console.log);

    console.log(formData);
    /*this.setState({ error: false, formError: "" });
    event.preventDefault();
    if (!this.formValidation()) return;
    const formData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      firstName: this.state.firstName
    };
    fetch("/api/create-user", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          if (this.state.email.includes("northumbria.ac.uk"))
            this.setState({ stage: 3 });
          else this.setState({ stage: 2 });
        } else {
          res.json().then(body => {
            this.setState({ formError: body.message, error: true });
          });
        }
      })
      .catch(console.log);*/
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;
    this.setState({
      showNav: scrollY > this.topSectionHeight,
    });
  }

  onScrollDown(event) {
    event.preventDefault();
    console.log(this.sectionRef);
    window.scrollTo({
      left: 0,
      top: this.sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Spill for Startups</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <TransparentNavigationBar />
        <TopSection ref={this.topSectionRef}>
          <React.Fragment>
            <Title>
              <Bold>Spill for Startups.</Bold>
            </Title>
            <CenteredDiv>
              <FillButton
                text="Apply for credit"
                action={(event) => this.onScrollDown(event)}
                borderColor={theme.colors.blue.normal}
                fontColor={theme.colors.blue.normal}
              />
              <WhiteParagraph>
                Get £700 worth of therapy credit for your team
              </WhiteParagraph>
            </CenteredDiv>
          </React.Fragment>
        </TopSection>

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>Simple Setup.</SectionTitle>
            <ThinParagraph>
              Spill integrates with Slack and starts by taking the emotional
              pulse of the company through a quick four-question survey. We then
              offer therapy sessions to those who are struggling.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={Onboarding} alt="Onboarding" />
          </Fade>
        </TwoColumnSection>

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>Active Support</SectionTitle>
            <ThinParagraph>
              Our therapists are fully trained professionals who are there to
              actually support your team. And we only hire the best out there,
              so you’re in safe hands.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={VideoTherapy} alt="Video Therapy" />
          </Fade>
        </TwoColumnSection>

        <TwoColumnSection>
          <CopySection>
            <SectionTitle>All-in-one Tool</SectionTitle>
            <ThinParagraph>
              Spill is there for everyone. You can get therapy if you’re not
              feeling great, ask a therapist a question if you need some advice
              or read our content and tools to keep on top of your emotional
              health.
            </ThinParagraph>
          </CopySection>
          <Fade>
            <ShadowImg src={MainMenu} alt="MainMenu" />
          </Fade>
        </TwoColumnSection>

        <CenteredSection>
          <SectionTitle centered>
            Get £700 therapy credit for your team
          </SectionTitle>

          <ItemDescription>
            10 therapy sessions and 10 questions to a therapist.
          </ItemDescription>

          <CenteredCTADiv>
            <FillButton
              text="Apply for credit"
              action={(event) => this.onScrollDown(event)}
              borderColor={theme.colors.blue.normal}
              fontColor={theme.colors.blue.normal}
            />
            <WhiteParagraph>
              Get £700 worth of therapy credit for your team
            </WhiteParagraph>
          </CenteredCTADiv>
        </CenteredSection>

        <Separator />

        <CenteredSection>
          <SectionTitle centered>Our partners</SectionTitle>

          <PressGrid>
            <PressLogo src={Passion} alt="Passion capital" />
            <PressLogo src={Seedcamp} alt="seedcamp" />
            <PressLogo src={Ada} alt="ada ventures" />
            <PressLogo src={Kindred} alt="kindred capital" />
          </PressGrid>
          <ListItem>
            <ItemDescription>
              You can{" "}
              <a
                href="https://spillchat.typeform.com/to/kbg2Cq"
                target="_blank"
              >
                apply here
              </a>{" "}
              to become a partner. Or if your investor isn’t on here, and they
              should be, let us know.
            </ItemDescription>
          </ListItem>
        </CenteredSection>

        <Separator />

        <CenteredSection ref={this.sectionRef}>
          <Fragment>
            <SectionTitle centered>Claim your £700 credit</SectionTitle>

            <Wrapper>
              <IntakeForm />
            </Wrapper>
          </Fragment>
        </CenteredSection>
        <Separator />
        <CenteredSection>
          <Fragment>
            <SectionTitle>FAQs</SectionTitle>

            <Wrapper>
              <LongSectionTitle>Am I eligible to apply?</LongSectionTitle>
              <Paragraph>
                Maybe, you need to be working with one of our partners (listed
                above) to be able to claim the free credits.
              </Paragraph>
              <LongSectionTitle>
                How much does £700 therapy credit get me?
              </LongSectionTitle>
              <Paragraph>
                £700 credit translates to 10 one-off therapy sessions and 10
                questions to a therapist. You can distribute these amongst your
                team however you like: 10 people can have one session, one
                person can have 10 sessions, etc. For a smaller team it should
                last a while.
              </Paragraph>
              <LongSectionTitle>What happens afterwards?</LongSectionTitle>
              <Paragraph>
                Spill's all-in-one emotional support normally costs around £8
                per employee per month (with a 30 day rolling contract). This
                includes therapy sessions for your team. If you want to continue
                after your credit has run out, you can move to a paid plan and
                keep full access to Spill. If you don't, you will automatically
                transition to the basic free plan, which includes access to
                content and tools (but not therapy sessions).
              </Paragraph>
              <LongSectionTitle>Can I be listed as a partner?</LongSectionTitle>
              <Paragraph>
                We are partnering with funds and communities that share our
                vision of making mental health support more accessible. If
                that’s you then we’d love to hear from you. You can{" "}
                <a
                  href="https://spillchat.typeform.com/to/kbg2Cq"
                  target="_blank"
                >
                  apply here
                </a>
                .
              </Paragraph>
            </Wrapper>
          </Fragment>
        </CenteredSection>
        <CenteredSection>
          <SectionTitle centered>
            Get £700 therapy credit for your team
          </SectionTitle>

          <ItemDescription>
            10 therapy sessions and 10 questions to a therapist.
          </ItemDescription>

          <CenteredCTADiv>
            <FillButton
              text="Apply for credit"
              action={(event) => this.onScrollDown(event)}
              borderColor={theme.colors.blue.normal}
              fontColor={theme.colors.blue.normal}
            />
            <WhiteParagraph>
              Get £700 worth of therapy credit for your team
            </WhiteParagraph>
          </CenteredCTADiv>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default withRouter(Startups);
