import styled from "styled-components";

export const CircleWrapper = styled.div`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  padding: ${({ theme, size }) => size / 50}rem;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallCircleWrapper = styled(CircleWrapper)`
  padding: ${({ theme }) => theme.spacing.xs2};
`;

export const HoverSvg = styled.svg`
  fill: ${({ theme }) => theme.colors.blue.extraLight};

  &:hover {
    fill: ${({ theme }) => theme.colors.primary.normal};
  }
`;
