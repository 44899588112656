import React, { Fragment } from "react";
import Helmet from "react-helmet";
import { StripeProvider, Elements } from "react-stripe-elements";

import { Section, PageDescription, HalfSection } from "./style";

import {
  SectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  LongSectionTitle,
} from "../../style/text";

import PaymentForm from "./Form";

const Billing = (props) => {
  const { email } = props.match.params;
  return (
    <Fragment>
      <Helmet>
        <title>Spill | Sign Up</title>
        <meta name="description" content="Sign up to Spill." />
        <meta name="keywords" content="Spill,Mental health,Chat,Support" />
      </Helmet>

      <Section>
        <HalfSection>
          <LongSectionTitle>Get set up with Spill.</LongSectionTitle>
          <PageDescription>
            We don't take any money from your card until after you launch.
          </PageDescription>
        </HalfSection>
        <HalfSection>
          <StripeProvider apiKey={process.env.STRIPE_API_KEY}>
            <Elements>
              <PaymentForm email={email} />
            </Elements>
          </StripeProvider>
        </HalfSection>
      </Section>
    </Fragment>
  );
};
export default Billing;
