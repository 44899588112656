import React from "react";

import { ArrowRight } from "../../assets/icons";
import { ButtonSolid, ButtonBorder, ClearButton, ArrowWrapper } from "./style";

export const FillButton = ({
  fillColor,
  fontColor,
  action,
  text,
  style,
  disabledProperty,
}) => (
  <ButtonSolid
    onClick={action}
    fillColor={fillColor}
    fontColor={fontColor}
    style={{ ...style }}
    disabled={disabledProperty}
  >
    {text}
  </ButtonSolid>
);

export const BorderButton = ({
  borderColor,
  fontColor,
  action,
  style,
  text,
}) => (
  <ButtonBorder
    style={{ ...style }}
    onClick={action}
    borderColor={borderColor}
    fontColor={fontColor}
  >
    {text}
  </ButtonBorder>
);

export const TextButton = ({ action, text, style }) => (
  <ClearButton onClick={action} style={{ style }}>
    {`${text}`}{" "}
    <ArrowWrapper>
      <ArrowRight size={12} />
    </ArrowWrapper>
  </ClearButton>
);
