import React, { Component, Fragment } from "react";
import ReactSVG from "react-svg";
import { Helmet } from "react-helmet";

import { ExclamationMarkIcon } from "../../assets/icons";
import { BorderButton } from "../../components/buttons/index";
import { CenteredSection } from "../../style/base-components";
import { SectionTitle, Paragraph, SmallParagraph } from "../../style/text";
import theme from "../../style/theme";
import {
  ThreeColumns,
  Underline,
  Form,
  Input,
  Wrapper,
  LinkText,
  LinkTextTertiary,
  Stage,
  Reason,
  Description,
  IconWrapper,
  ErrorMessage,
  StoreContainer,
  StoreImg,
  ButtonContainer,
  CenteredDiv
} from "./style";
import IOSStore from "../../assets/IOSAPPSTORE.svg";

class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: -1,
      email: "",
      firstName: "",
      password: "",
      error: false,
      formError: "",
      userType: "user"
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
  }
  componentDidMount() {
    
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeName(event) {
    this.setState({ firstName: event.target.value });
  }

  onChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  formValidation() {
    if (this.state.firstName.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter your name"
      });
      return false;
    }
    if (this.state.email.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter a valid email"
      });
      return false;
    }
    if (this.state.password.trim().length < 6) {
      this.setState({
        error: true,
        formError: "Please enter a password greater than 6 characters"
      });
      return false;
    }
    return true;
  }

  submitForm(event) {
    console.log(event)
    this.props.history.push('/sign-up/' + this.state.email)

  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Start | Spill</title>
          <meta
            name="description"
            content="Spill lets you message your designated counsellor every day. Affordable talking therapy whenever and wherever you need it."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <CenteredSection>
          <Fragment>
            
            <Fragment>

                <Fragment>
                  <SectionTitle centered>Let's get you signed up.</SectionTitle>
                  <Wrapper>
                      <Paragraph>
                        We won't just throw you straight in at the deep end: the first few messages are a guided introduction to therapy by our Spill welcome team.
                      </Paragraph>
                      <Paragraph>
                        After telling the welcome team a bit about your life, you'll be matched with one of our fully-qualified counsellors - the one best suited to your situation.</Paragraph>
                        <Paragraph>
                        Recieve two letters a week from your counsellor. You keep the same therapist throughout. They'll guide the conversation and might include tasks at times.
                        </Paragraph>
                    <Form>

                        <Input
                          placeholder="Email Address"
                          type="email"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                          title="email"
                          aria-label="email"
                        />

                    </Form>
                    
                    <CenteredDiv>
                      <BorderButton
                        text="create account"
                        borderColor={theme.colors.blue.normal}
                        fontColor={theme.colors.blue.normal}
                        action={event => {
                          this.submitForm(event);
                        }}
                      />
                    </CenteredDiv>
                    {this.state.error && (
                      <ErrorMessage centered>
                        {this.state.formError}
                      </ErrorMessage>
                    )}
                  </Wrapper>

              
                </Fragment>
              
            </Fragment>
          </Fragment>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default Start;
