import styled from "styled-components";
import devices from "../../style/devices";
import { SectionTitle } from "../../style/text";
import { BorderButton } from "../buttons";

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.blue.normal};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.m1};

  @media ${devices.tablet} {
    align-items: center;
  }

  @media ${devices.desktop} {
    padding: ${({ theme }) => theme.spacing.m3};
    align-items: center;
  }
`;

export const WhiteSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.white};
  align-items: flex-start;

  @media ${devices.tablet} {
    align-items: flex-start;
  }

  @media ${devices.desktop} {
    padding: ${({ theme }) => `${theme.spacing.m3} ${theme.spacing.l3}`};
    align-items: flex-start;
    max-width: 1024px;
    margin: 0 auto;
  }
`;
export const WhiteSectionCentered = styled(Section)`
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;

  @media ${devices.tablet} {
    align-items: center;
  }

  @media ${devices.desktop} {
    padding: ${({ theme }) => `${theme.spacing.m3} ${theme.spacing.l3}`};
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
  }
`;

export const Title = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 3rem;
  text-align: center;
`;

export const BlackTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 5rem;

  @media ${devices.tablet} {
    flex-direction: row;
    width: 35rem;
    height: auto;
    justify-content: center;
  }
`;

export const Button = styled(BorderButton)`
  display: none;
  visibility: hidden;

  @media ${devices.tablet} {
    display: flex;
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  max-width: 30rem;
  flex-direction: column;

  @media ${devices.tablet} {
    max-width: 50rem;
    flex-direction: row;
  }
`;

export const EmailInput = styled.input`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue.light};
  min-width: 4rem;
  font-size: ${({ theme }) => theme.font.size[3]};
  border-radius: 2rem;
  padding: ${({ theme }) => `${theme.spacing.xs1} ${theme.spacing.s1}`};
  border: 0;
  box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.2);
  font-family: ${({ theme }) => theme.font.family.Lato};
  flex: 2;
  margin-bottom: ${({ theme }) => theme.spacing.s1};
  line-height: 2;

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.5;
  }

  @media ${devices.tablet} {
    padding: 1rem 2rem;
    font-size: ${({ theme }) => theme.font.size[5]};
    min-width: 25rem;
    border-radius: 3rem;
    width: 100%;
    margin: 0;
    margin-right: ${({ theme }) => theme.spacing.s1};
    line-height: 1.2;
  }
`;

export const GreyBackgroundEmailInput = styled(EmailInput)`
  background-color: ${({ theme }) => theme.colors.grey[300]};
  outline-color: ${({ theme }) => theme.colors.primary.normal};
  outline-width: 1px;
  color: ${({ theme }) => theme.colors.black};
  /* width: 100%; */

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey[600]};
    opacity: 1;
  }
`;

export const BorderEmailInput = styled.input`
  min-width: 4rem;
  font-size: ${({ theme }) => theme.font.size[3]};
  border-radius: 2rem;
  padding: ${({ theme }) => `${theme.spacing.xs1} ${theme.spacing.s1}`};
  font-family: ${({ theme }) => theme.font.family.Lato};
  margin-right: ${({ theme }) => theme.spacing.s1};
  width: 100%;
  flex: 2;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grey[500]};

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[700]};
    opacity: 0.5;
  }

  @media ${devices.tablet} {
    padding: 1rem 2rem;
    font-size: ${({ theme }) => theme.font.size[5]};
    min-width: 25rem;
    border-radius: 3rem;
  }
`;

export const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
