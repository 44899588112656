import React, { Fragment, Component } from "react";
import Helmet from "react-helmet";
import { StripeProvider, Elements } from "react-stripe-elements";
import * as qs from "query-string";

import { Section, PageDescription, HalfSection, TitleSection } from "./style";

import {
  SectionTitle,
  Paragraph,
  ThinParagraph,
  SmallHeading,
  LongSectionTitle,
} from "../../style/text";

import PaymentForm from "./Form";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: qs.parse(this.props.location.search),
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Spill | Sign Up</title>
          <meta name="description" content="Sign up to Spill." />
          <meta name="keywords" content="Spill,Mental health,Chat,Support" />
        </Helmet>
        <TitleSection>
          <SectionTitle centered>
            Sign up and get started on {this.state.plan.plan} cover.
          </SectionTitle>
          <Paragraph centered>
            No yearly commitment 💍 | Cancel with 30 days notice 👋 | Switch
            plans whenever 🔀
          </Paragraph>
        </TitleSection>

        <Section>
          <HalfSection>
            <SmallHeading>Next steps</SmallHeading>
            <Paragraph>
              Step 1: Sign up with card - no payment will be taken until after
              you launch Spill
            </Paragraph>
            <Paragraph>Step 2: Book in a launch talk for your team</Paragraph>
            <Paragraph>
              Step 3: Call with somebody from the Spill onboarding team to get
              you set up
            </Paragraph>
            <Paragraph>
              Step 4: After the launch talk you add Spill to Slack and Spill
              will DM everyone in your team to onboard them
            </Paragraph>
          </HalfSection>
          <HalfSection>
            <SmallHeading>Sign up with card</SmallHeading>
            <StripeProvider apiKey={process.env.STRIPE_API_KEY}>
              <Elements>
                <PaymentForm />
              </Elements>
            </StripeProvider>
          </HalfSection>
        </Section>
      </Fragment>
    );
  }
}
export default Billing;
