import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import {
  TwoColumnSection,
  CopySection,
  Drawing,
  CenteredSection,
  ShadowImg,
  Img,
  TitleSection,
  StyledButton,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  WhiteParagraph,
  LongSectionTitleNoPadding,
  ResourceLink,
} from "../../style/text";
import {
  TopSection,
  Title,
  Bold,
  ButtonContainer,
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  ListContainer,
  ListGrid,
  ListTitle,
  ListItem,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  CompanyGrid,
  CompanyLogo,
  PressGrid,
  PressLogo,
  Form,
  Input,
  Wrapper,
  CenteredDiv,
  CenteredCTADiv,
  MultiLineInput,
} from "./style";

import One from "../../assets/resources/1course.png";
import Two from "../../assets/resources/2one.png";
import Three from "../../assets/resources/3message.png";
import Four from "../../assets/resources/4reading.png";
import Five from "../../assets/resources/5tools.png";
import Six from "../../assets/resources/6manager.png";

import Passion from "../../assets/images/passion.png";
import Seedcamp from "../../assets/images/seedcamp.png";
import GFS from "../../assets/images/gfs.png";
import Kindred from "../../assets/images/kindred.png";

import EveningStandardLogo from "../../assets/pressLogos/evening-standard.png";
import GraziaLogo from "../../assets/pressLogos/grazia.png";
import StylistLogo from "../../assets/pressLogos/stylist.png";
import TheIndependentLogo from "../../assets/pressLogos/the-independent.png";
import TheTimesLogo from "../../assets/pressLogos/the-times.png";

import Separator from "../../components/separator/index";
import {
  GetSpill,
  GetUpdates,
  WhiteCTA,
  StayUpToDate,
} from "../../components/callToAction/index";
import {
  TransparentNavigationBar,
  WhiteNavigationBar,
} from "../../components/navigation/index";
import {
  FillButton,
  BorderButton,
  TextButton,
} from "../../components/buttons/index";
import theme from "../../style/theme";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      formSuccess: false,
      page: 0,
      accountURL: "",
    };
    this.sectionRef = React.createRef();
    this.topSectionRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeInvestor = this.onChangeInvestor.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getSignupLink = this.getSignupLink.bind(this);
    this.onScrollDown = this.onScrollDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const hash = qs.parse(this.props.location.hash);
    console.log(this.props.location.pathname);
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeCompanyName(event) {
    this.setState({ companyName: event.target.value });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeInvestor(event) {
    this.setState({ investor: event.target.value });
  }

  getSignupLink() {
    const formData = {
      companyId: "816575aa-af50-481a-93ec-e57fe57d46ef"
    };
    fetch("/api/get-signup-link", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((body) => {
            window.open(body.message);
            console.log(body);
          });
        } else {
          res.json().then((body) => {
            this.setState({ formError: body.message, error: true });
          });
        }
      })
      .catch(console.log);
  }

  clickedGetStarted() {
    this.setState({ page: 1 });
  }

  submitForm(event) {
    const formData = {
      name: this.state.name,
      companyName: this.state.companyName,
      email: this.state.email,
      investor: this.state.investor,
      subject: "New Credit Claim",
    };

    fetch("/api/new-gift", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("cool");
        this.setState({ formSuccess: true });
      })
      .catch(console.log);

    console.log(formData);
    /*this.setState({ error: false, formError: "" });
    event.preventDefault();
    if (!this.formValidation()) return;
    const formData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      firstName: this.state.firstName
    };
    fetch("/api/create-user", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          if (this.state.email.includes("northumbria.ac.uk"))
            this.setState({ stage: 3 });
          else this.setState({ stage: 2 });
        } else {
          res.json().then(body => {
            this.setState({ formError: body.message, error: true });
          });
        }
      })
      .catch(console.log);*/
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;
    this.setState({
      showNav: scrollY > this.topSectionHeight,
    });
  }

  onScrollDown(event) {
    event.preventDefault();
    console.log(this.sectionRef);
    window.scrollTo({
      left: 0,
      top: this.sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Resources | Spill</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>

        {this.state.page === 0 ? (
          <div>
            <TitleSection>
              <SectionTitle centered>Your Spill resources</SectionTitle>
            </TitleSection>

            <TwoColumnSection>
              <CopySection>
                <Fade>
                  <Img src={One} alt="Onboarding" />
                </Fade>
                <LongSectionTitleNoPadding>
                  Request a course of therapy
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  If you’re struggling with low mood or anxiety, or any other
                  difficult emotions that impact your daily life, talk to a
                  therapist.
                </ThinParagraph>
                <StyledButton onClick={() => this.clickedGetStarted()}>
                  Get started ➔
                </StyledButton>
              </CopySection>
              <CopySection>
                <Fade>
                  <Img src={Two} alt="Video Therapy" />
                </Fade>
                <LongSectionTitleNoPadding>
                  Book a one-off therapy session
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  Get emotional coaching or talk through something with a
                  therapist, without committing to a whole course of therapy.
                </ThinParagraph>
                <StyledButton onClick={() => this.clickedGetStarted()}>
                  Get started ➔
                </StyledButton>
              </CopySection>
            </TwoColumnSection>

            <TwoColumnSection>
              <CopySection>
                <Fade>
                  <Img src={Three} alt="MainMenu" />
                </Fade>
                <LongSectionTitleNoPadding>
                  Send a one-off question to a therapist
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  Ask any question about mental health or emotional wellbeing,
                  without commiting to the full process of therapy. You'll get a
                  reply within one working day.
                </ThinParagraph>
                <StyledButton onClick={() => this.clickedGetStarted()}>
                  Get started ➔
                </StyledButton>
              </CopySection>
              <CopySection>
                <Fade>
                  <Img src={Four} alt="Onboarding" />
                </Fade>
                <LongSectionTitleNoPadding>
                  Browse our reading recommendations
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  Look through our curated list of resources, designed to help
                  you learn and grow.
                </ThinParagraph>
                <a href="https://home.spill.chat/reading">
                  <StyledButton>Browse reading ➔</StyledButton>
                </a>
              </CopySection>
            </TwoColumnSection>

            <TwoColumnSection>
              <CopySection>
                <Fade>
                  <Img src={Five} alt="Video Therapy" />
                </Fade>
                <LongSectionTitleNoPadding>
                  Browse exercises from our mental health and wellbeing toolkit
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  A range of exercises to help you change how you think, work,
                  feel and behave.
                </ThinParagraph>
                <a href="https://home.spill.chat/tools">
                  <StyledButton>Browse tools ➔</StyledButton>
                </a>
              </CopySection>
              <CopySection>
                <Fade>
                  <Img src={Six} alt="Video Therapy" />
                </Fade>
                <LongSectionTitleNoPadding>
                  Send a question to our manager advice line
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  Ask a question about how to assist someone else. Feel free to
                  use it even if you're not a manager.
                </ThinParagraph>
                <StyledButton onClick={this.clickedGetStarted}>
                  Get started ➔
                </StyledButton>
              </CopySection>
            </TwoColumnSection>
          </div>
        ) : (
          <div>
            <TitleSection>
              <SectionTitle centered>
                Have you had therapy on Spill before?
              </SectionTitle>
            </TitleSection>

            <TwoColumnSection>
              <CopySection>
                <LongSectionTitleNoPadding>
                  Yes, I have
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  Click the link below to log in to your Spill account. You'll
                  log in using the email you've used to sign up to Spill before.
                </ThinParagraph>
                <a href="https://app.spill.chat/signin">
                  <StyledButton>Log in ➔</StyledButton>
                </a>
              </CopySection>
              <CopySection>
                <LongSectionTitleNoPadding>
                  No, I haven't{" "}
                </LongSectionTitleNoPadding>
                <ThinParagraph>
                  Click the link below to create your Spill account. You'll be
                  able to use your work or personal email to register.
                </ThinParagraph>

                <StyledButton onClick={() => this.getSignupLink()}>
                  Create account ➔
                </StyledButton>
              </CopySection>
            </TwoColumnSection>
            <TwoColumnSection />
          </div>
        )}

        <Separator />
        <CenteredSection>
          <Fragment>
            <SectionTitle>FAQs</SectionTitle>

            <Wrapper>
              <LongSectionTitle>Is Spill confidential?</LongSectionTitle>
              <Paragraph>
                Yes. Spill is completely confidential. We only ever share
                aggregate usage statistic with your employer.
                <br />
                <br />
                If you choose to get therapy through Spill then anything you
                tell your therapist is confidential and will never be shared
                with your employer.
              </Paragraph>
              <LongSectionTitle>Who are the Spill therapists?</LongSectionTitle>
              <Paragraph>
                All Spill therapists are registered with BACP, UKCP, or another
                UK Standards Authority governing body. Every Spill Therapist has
                completed at least three years of academic therapy training and
                has at least 100 clinical hours under training supervision. Most
                of our therapists have been practising for decades.
                <br />
                <br />
                Spill's hiring process is particularly stringent. Therapists go
                through a five-step application process designed to test for
                various competencies. Less than 5% of applicants are offered
                roles at Spill.
              </Paragraph>
              <LongSectionTitle>
                Can I change my therapist if I want to?
              </LongSectionTitle>
              <Paragraph>
                Absolutely. We know that the relationship with your therapist is
                really important. Just email us at hi@spill.chat and we'll
                handle things from there.
              </Paragraph>
              <LongSectionTitle>
                How are the therapy sessions conducted?
              </LongSectionTitle>
              <Paragraph>
                You get to choose how you want to communicate with your
                therapist. You can pick from phone, video or message-based
                therapy.
                <br />
                <br />
                Once you decide how you want to communicate, you can choose,
                with your therapist how you want to break down the amount of
                therapy you have. You can keep them as weekly 50-minutes
                sessions or turn them into frequent, shorter sessions, or
                receive the equivalent amount of therapy over messages.
              </Paragraph>
              <LongSectionTitle>
                What happens during the initial consultation?
              </LongSectionTitle>
              <Paragraph>
                The goal of the consultation is for you and your therapist to
                meet, discuss the presenting problem, set expectations for your
                time together, set goals where appropriate, and create a plan
                for the coming sessions.
                <br />
                <br />
                <br />
                If you have a question that hasn't been answered then you can
                email us on hi@spill.chat.
              </Paragraph>
            </Wrapper>
          </Fragment>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default withRouter(Resources);
